import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux"
import { FormGroup, Label, InputGroupAddon, FormText, InputGroup, Col, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Form } from "reactstrap";
import { createCoupon } from '../context/actions/coupons';
import StarWebPrint from '../services/print';
import { updateLoading } from '../context/actions/app';

export default (props) => {
    const [ amount, setAmount ] = useState('');
    const [ description, setDescription ] = useState('');
    const amountInput = useRef(null);
    const [ confirm, setConfirm ] = useState(false);
    const dispatch = useDispatch();

    /**
     * 
     * @param {*} e 
     */
    const resetForm = () => {
        setAmount('');
        setDescription('');
        setConfirm(false);
    }
    
    const handleModalSubmit = e => {
        e.preventDefault();

        if (!confirm || !amount) return;

        dispatch(createCoupon({ amount, description })).then(coupon => {
            updateLoading(false);

            if (coupon) {
                StarWebPrint.print('coupon', coupon);
                resetForm();
                amountInput.current.focus();
            }
        })

        // if (!confirm) return;

        // dispatch(updateCoupon(action, coupon.id, {
        //     code: coupon.code,
        //     amount: String(apiAmount),
        // })).then(() => {
        //     setAmount('');
        //     setConfirm(false);
        // });
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} onOpened={() => { amountInput.current.focus() }} className="modal-xl" centered>
            <ModalHeader toggle={props.toggle}><i className="fa fa-gift" style={{marginRight: '8px', marginBottom: '1px'}} />Opret gavekort</ModalHeader>
            <ModalBody>
                <Form onSubmit={e => { e.preventDefault(); amountInput.current.blur(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="order_id">Beskrivelse:</Label>
                                <textarea rows="5" className="form-control" onChange={e => { setDescription(e.target.value) }} value={description}></textarea>
                                <FormText color="muted">
                                    Det er valgfrit om du vil indtaste en beskrivelse. Beskrivelsen er kun til intern brug og vil ikke fremgå af gavekortet.
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup style={{marginBottom: '30px'}}>
                                <Label for="order_id" className="required">Beløb:</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">kr.</InputGroupAddon>
                                    <input type="number" step=".01" name="order_id" className="form-control-lg form-control" bsSize="lg" value={amount} onChange={e => { setAmount(e.target.value) }} autoComplete="off" ref={amountInput} required />
                                </InputGroup>
                                <FormText color="muted">
                                    Indtast det ønskede beløb, inklusiv moms.
                                </FormText>
                            </FormGroup>
                            { amount && 
                                <FormGroup check>
                                    <div className="custom-checkbox custom-control custom-control-inline" style={{marginBottom: '30px'}}>
                                        <input type="checkbox" id="exampleCustomInline" className="custom-control-input" onChange={e => { setConfirm(!confirm) }} checked={confirm} />
                                        <label className="custom-control-label" for="exampleCustomInline">Bekræft venligst at du ønsker at oprette et gavekort på <b>kr. {parseFloat(amount).toFixed(2)}</b>.</label>
                                    </div>
                                </FormGroup>
                            }
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter style={{display: 'initial'}}>
                    <ButtonGroup size="lg" className="float-right">
                        <Button color="success" style={{ width: '200px' }} type="button" onMouseDown={handleModalSubmit} disabled={!confirm}>Opret gavekort</Button>
                    </ButtonGroup>
                </ModalFooter>
        </Modal>
    )
}
