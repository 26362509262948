const initialState = {
    loadingCoupons: false,
    // loadingOrder: true,
    coupons: [],
    searchResults: [],
    coupon: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_COUPON_IN_LISTS':
            // ...
            for (let index in state.coupons) {
                if (state.coupons[index].id === action.data.id) {
                    state.coupons[index] = action.data;
                }
            }

            // ...
            for (let index in state.searchResults) {
                if (state.searchResults[index].id === action.data.id) {
                    state.searchResults[index] = action.data;
                }
            }

            return state;
        case 'SET_COUPONS_LOADING':
            return { ...state, loadingCoupons: action.data };
        case 'SET_COUPON_SEARCH_RESULTS':
            return { ...state, searchResults: action.data };
        case 'SET_SELECTED_COUPON':
            return { ...state, coupon: action.data };
        default:
            return state;
    }
}
