import { StarWebPrintTrader, StarWebPrintBuilder } from 'digitickets.star-web-print';
import { formatLeadingZero } from '../helpers/number';
import { formatDeliveryFullDate } from '../helpers/date';
import { getWAPFMeta, getPurchaseNote, getDeliveryDate, getMetaData } from '../helpers/order';
// import Axios from "axios"

const builder = new StarWebPrintBuilder();



const getTrader = (statusCallback) => {
    const trader = new StarWebPrintTrader({
        url: 'https://localhost:8001/StarWebPRNT/SendMessage',
        checkedblock: true,
    });

    // trader.onReceive = () => {}
    // if (successCallback !== undefined) {
    //     trader.onReceive = successCallback;
    // }

    // trader.onError = () => {}
    // if (errorCallback !== undefined) {
    //     trader.onError = errorCallback;
    // }

    trader.onError = () => {};
    trader.onReceive = () => {};
    if (statusCallback !== undefined) {
        trader.onError = (res) => {
            statusCallback(res, false)
        };
        trader.onReceive = (res) => {
            statusCallback(res, !trader.isOffLine({ traderStatus: res.traderStatus }))
        };
    }

    return trader;
}

let details = {};

const stripHtml = (html) => {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

const Receipt = {

    logo() {
        let request = '';

        if (details.logo_context) {
            request += builder.createAlignmentElement({ position: 'center' });
            request += builder.createBitImageElement({
                context: details.logo_context, x: 0, y: 0,
                width: details.logo.width,
                height: details.logo.height
            });

            request += builder.createAlignmentElement({ position: 'left' });
        }

        return request;
    },

    orderInfo(order) {
        let request = '';

        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ width: 2, height: 2, linespace: 32, emphasis: true, data: 'Ordre #' + order.number + '\n' });

        let deliveryDate = getDeliveryDate(order);
        if (deliveryDate) {
            request += builder.createTextElement({ width: 2, height: 2, linespace: 32, emphasis: false, data: order.shipping_lines[0].method_title + '\n' });
            request += builder.createTextElement({ width: 2, height: 2, linespace: 32, emphasis: false, data: formatDeliveryFullDate(deliveryDate) + '\n' });
        }

        // ...
        if (order._number_series !== undefined) {
            request += builder.createTextElement({ data: '\n' });
            for (let index = 0; index < order._number_series.length; index++) {
                const _number_serie = order._number_series[index];
                request += builder.createTextElement({ width: 2, height: 2, linespace: 32, emphasis: true, data: 'Bestillingsnr.\n' });
                request += builder.createTextElement({ width: 2, height: 2, linespace: 32, emphasis: false, data: _number_serie.name + '\n' });
                request += builder.createTextElement({ width: 2, height: 2, linespace: 32, emphasis: false, data: _number_serie.value + '\n' });
                request += builder.createTextElement({ data: '\n' });
            }
        }

        // ...
        if (details !== undefined && details['text'] !== undefined && details['text']['receipt'] !== undefined && Array.isArray(details['text']['receipt']) === true && details['text']['receipt'].length > 0) {

            request += builder.createTextElement({ data: '\n' });
            
            request += builder.createAlignmentElement({ position: 'left' });
            request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false });

            for (let index = 0; index < details['text']['receipt'].length; index++) {
                const line = details['text']['receipt'][index];
                request += builder.createTextElement({ data: ' '+ line.substring(0, 47) +'\n' });
            }
        }


        return request;
    },

    // paymentInfo(order) {
    //     let request = '';

    //     request += builder.createAlignmentElement({ position: 'center' });
    //     request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '\n' });
    //     request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '------------------------------------------------\n' });
    //     request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '\n' });

    //     request += builder.createAlignmentElement({ position: 'center' });
    //     request += builder.createTextElement({ font: 'font_b', width: 2, height: 2, linespace: 32, emphasis: true, data: 'Betaling:\n' });

    //     request += builder.createTextElement({ font: 'font_a', width: 2, height: 2, linespace: 24, emphasis: false, data: (order.date_paid ? (order.payment_method_title ? order.payment_method_title : 'Betalt') : 'Mangler betaling') + '\n' });

    //     return request;
    // },

    orderTotal(order) {
        let request = '';

        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '\n' });
        request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '------------------------------------------------\n' });
        request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '\n' });

        const shipping_total = Number(order.shipping_total) + Number(order.shipping_tax)
        const subtotal = Number(order.total) - shipping_total

        // console.log(order, subtotal);

        if (shipping_total > 0) {
            request += builder.createAlignmentElement({ position: 'center' });
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: 'Subtotal: ' + subtotal.toFixed(2) + ' kr.\n' });

            request += builder.createAlignmentElement({ position: 'center' });
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: 'Levering: ' + shipping_total.toFixed(2) + ' kr.\n' });
            request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '\n' });
        }

        if (order['coupon_lines'] !== undefined && order.coupon_lines.length > 0) {
            for (let index = 0; index < order.coupon_lines.length; index++) {
                const coupon       = order.coupon_lines[index];
                const coupon_meta  = getMetaData(coupon, 'coupon_data');
                const coupon_total = Number(coupon.discount) + Number(coupon.discount_tax);

                let coupon_title = 'Rabatkode: ';
                if (coupon_meta && coupon_meta['meta_data'] !== undefined) {
                    const giftcard  = getMetaData(coupon_meta, 'giftcard');

                    if (giftcard === 'yes') {
                        coupon_title = 'Betalt med gavekort: ';
                    }
                }

                request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: coupon_title + coupon_total.toFixed(2) + ' kr.\n' });
            }

            request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '\n' });
        }

        request += builder.createTextElement({ width: 2, height: 2, linespace: 24, emphasis: false, data: (order.date_paid ? (order.payment_method_title ? order.payment_method_title : 'Betalt') : 'Mangler betaling') + '\n' });
        request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '\n' });

        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ width: 2, height: 2, linespace: 32, emphasis: true, data: 'Total: ' + order.total + ' kr.\n' });
        request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '\n' });


        return request;
    },

    customerInfo(order) {
        if (order['shipping'] === undefined) {
            return '';
        }

        let request = '';
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '------------------------------------------------\n' });

        request += builder.createAlignmentElement({ position: 'left' });

        if (order.billing['company'] !== undefined && order.billing['company'] !== '') {
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: 'Firma:    ' + order.billing['company'] + '\n' });
        }

        const vatNumber = getMetaData(order, '_billing_company_vat_number');
        if (vatNumber && vatNumber !==  '') {
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: 'CVR:      ' + vatNumber + '\n' });
        }

        const eanNumber = getMetaData(order, '_billing_company_ean_number');
        if (eanNumber && eanNumber !== '') {
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: 'EAN:      ' + eanNumber + '\n' });
        }

        if (order.shipping['first_name'] !== undefined && order.shipping['last_name'] !== undefined) {
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: 'Navn:     ' + order.shipping.first_name + ' ' + order.shipping.last_name + '\n' });
        }

        if (order.shipping['address_1'] !== undefined && order.shipping['address_1'] !== '') {
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: 'Adresse:  ' + order.shipping.address_1 + '\n' });
        }

        if (order.shipping['address_2'] !== undefined && order.shipping['address_2'] !== '') {
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: '          ' + order.shipping.address_2 + '\n' });
        }

        if (order.shipping['postcode'] !== undefined && order.shipping['city'] !== undefined) {
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: '          ' + order.shipping.postcode + ' ' + order.shipping.city + '\n' });
        }

        if (order.billing['phone'] !== undefined && order.billing['phone'] !== '') {
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: 'Telefon:  ' + order.billing.phone + '\n' });
        }

        // request += this.paymentInfo(order);

        //request += builder.createTextElement({ width: 2, height: 2, linespace: 32, emphasis: false, data: 'Betaling: ' + (order.date_paid ? (order.payment_method_title ? order.payment_method_title : 'Betalt') : 'Mangler betaling') + '\n' });

        if (order.customer_note !== '') {
            request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '\n' });
            request += builder.createAlignmentElement({ position: 'center' });
            request += builder.createTextElement({ width: 2, height: 2, linespace: 32, emphasis: true, data: 'Bemærkning:\n' });
            request += builder.createTextElement({ width: 1, height: 1, linespace: 32, emphasis: false, data: order.customer_note + '\n' });
            request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '\n' });
        }

        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ width: 1, height: 1, linespace: 24, emphasis: false, data: '------------------------------------------------\n' });

        return request;
    },

    header() {
        let request = '';
        request += builder.createTextElement({ codepage: 'utf8' });
        request += builder.createTextElement({ characterspace: 0 });
        // request += builder.createAlignmentElement({position:'right'});
        // request += builder.createLogoElement({number:1});
        // request += builder.createTextElement({data:'TEL 9999-99-9999\n'});
        // request += builder.createAlignmentElement({position:'left'});
        // request += builder.createTextElement({data:'\n'});
        // request += builder.createAlignmentElement({position:'center'});
        // request += builder.createTextElement({data:'Thank you for your coming. \n'});
        // request += builder.createTextElement({data:"We hope you'll visit again.\n"});

        request += builder.createAlignmentElement({ position: 'center' });
        if (details !== undefined && details['company'] !== undefined) {
            if (details.company !== '') {
                request += builder.createTextElement({ width: 1, emphasis: true, data: details.company + '\n' });
            }
        } else {
            request += builder.createTextElement({ width: 1, emphasis: true, data: 'Min Demo Forretning\n' });
        }

        if (details !== undefined && details['address'] !== undefined) {
            details.address.split(/\r\n|\r|\n/g).forEach(function (line) {
                request += builder.createTextElement({ width: 1, emphasis: false, data: line + '\n' });
            });
        } else {
            request += builder.createTextElement({ width: 1, emphasis: false, data: 'Demovejen 13\n' });
            request += builder.createTextElement({ width: 1, emphasis: false, data: '9999 Demoby\n' });
        }

        if (details !== undefined && details['vat'] !== undefined) {
            if (details.vat !== '') {
                request += builder.createTextElement({ width: 1, emphasis: false, data: 'CVR: ' + details.vat + '\n' });
            }
        } else {
            request += builder.createTextElement({ width: 1, emphasis: false, data: 'CVR: 12345678\n' });
        }

        if (details !== undefined && details['phone'] !== undefined) {
            if (details.phone !== '') {
                request += builder.createTextElement({ width: 1, emphasis: false, data: 'Tlf: ' + details.phone + '\n' });
            }
        } else {
            request += builder.createTextElement({ width: 1, emphasis: false, data: 'Tlf: 12 34 56 78\n' });
        }

        return request;
    },

    products(order) {
        if (order['line_items'] === undefined) {
            return '';
        }

        let request = '';

        request += builder.createAlignmentElement({ position: 'left' });

        order.line_items.forEach(item => {
            let qty = ' ' + item.quantity.toString().padStart(3, ' ') + ' x ';
            let name = item.name;
            // let price   = parseFloat(item.price.toString()).toFixed(2);
            let price = '';

            // ...
            let max_length = 46;
            let qty_price_length = String(qty).length + String(price).length;
            let pad_length = max_length - qty_price_length;
            let max_product_length = pad_length - 1;

            // ...
            const pattern = '.{1,' + max_product_length + '}'
            const lines = name.match(new RegExp(pattern, 'g'));

            // ...
            lines.forEach((line, index) => {
                let str = qty + line.trim().padEnd(pad_length, ' ') + price;
                if (index) {
                    str = ' '.padStart(String(qty).length, ' ') + line.trim().padEnd(pad_length, ' ');
                }

                request += builder.createTextElement({ data: str + '\n' });
            });

            if (getPurchaseNote(item).length > 0) {
                request += builder.createTextElement({ data: '\n' });
                request += builder.createTextElement({ width: 1, height: 1, data: getPurchaseNote(item) + '\n' });
            }

            if (getWAPFMeta(item).length > 0) {
                getWAPFMeta(item).map((meta, _index) => {
                    request += builder.createTextElement({ data: '\n' });
                    request += builder.createTextElement({ width: 1, height: 1, emphasis: true, data: meta.label + '\n' });
                    // request += builder.createTextElement({ width: 1, height: 1, emphasis: false, data: stripHtml(meta.value).replaceAll("., ", '.\n') +'\n' });
                    request += builder.createTextElement({ width: 1, height: 1, emphasis: false, data: stripHtml(meta.value).replace(/\., /g, '.\n').replace(/\. \(\+kr\.(.*?)\), /, '. (+kr.$1)\n') + '\n' });

                    return '';
                })

                request += builder.createTextElement({ emphasis: true, data: '\n' });
            }
        });





        // request += builder.createTextElement({emphasis:true, data:'Subtotal                                 $200.00\n'});
        // request += builder.createTextElement({data:'\n'});
        // request += builder.createTextElement({underline:true, data:'Tax                                       $10.00\n'});
        // request += builder.createTextElement({underline:false});
        // request += builder.createTextElement({emphasis:true});
        // request += builder.createTextElement({width:2, data:'Total            $210.00\n'});
        // request += builder.createTextElement({width:1});
        // request += builder.createTextElement({emphasis:false});
        // request += builder.createTextElement({data:'\n'});
        // request += builder.createTextElement({data:'Received                                 $300.00\n'});
        // request += builder.createTextElement({width:2, data:'Change            $90.00\n'});
        // request += builder.createTextElement({width:1});
        // request += builder.createTextElement({data:'\n'});

        request += builder.createTextElement({ characterspace: 0 });

        

        // console.log(request);

        return request;
    },

    giftcard(coupon) {
        return this.coupon(coupon);
    },

    coupon(coupon) {

        if (!coupon['code']) {
            return;
        }

        let request = '';

        request += this.header();
        // request += builder.createTextElement({ data:'\n' });
        request += this.qr(coupon.code);


        return request;
    },

    qr(code) {
        let request = '';

        request += builder.createAlignmentElement({ position: 'center' });

        request += builder.createQrCodeElement({
            model: 'model2',
            level: 'level_h',
            cell: 8,
            data: code
        });

        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: 'Gavekortskode:\n' });
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ width: 2, height: 2, emphasis: true, data: code + '\n' });
        request += builder.createAlignmentElement({ position: 'left' });
        request += builder.createTextElement({ width: 1, height: 1, emphasis: false });

        return request;
    }
}

const StarWebPrint = {
    setup(data) {
        details = data;

        // alert(JSON.stringify(data.logo));

        if (data.logo !== undefined && data.logo) {

            const canvas  = document.createElement('canvas');
            canvas.setAttribute('id', 'receipt-logo');
            canvas.width  = data.logo.width;
            canvas.height = data.logo.height;

            const context = canvas.getContext('2d');

            const image = new Image();
            image.src = data.logo.src;
            image.onload = function () {
                context.drawImage(image, 0, 0);
            }

            details.logo_context = context;
        } else {
            details.logo_context = false;
        }
    },

    ping(statusCallback) {
        const trader = getTrader(statusCallback);

        trader.sendMessage({});
    },

    send(request, statusCallback) {
        const trader = getTrader(statusCallback);

        trader.sendMessage({ request });
    },

    receipt(order, statusCallback) {
        return this.order(order, statusCallback);
    },

    order(order, statusCallback) {
        let request = builder.createInitializationElement();

        // header
        request += Receipt.logo();
        request += builder.createTextElement({ width: 1, height: 1, data: '\n' });

        request += Receipt.header();
        request += builder.createTextElement({ width: 1, height: 1, data: '\n' });
        // request += builder.createTextElement({ width: 1, height: 1, data: '\n' });

        request += Receipt.orderInfo(order);
        request += builder.createTextElement({ width: 1, height: 1, data: '\n' });

        request += Receipt.customerInfo(order);
        request += builder.createTextElement({ width: 1, height: 1, data: '\n' });


        request += Receipt.products(order)


        request += Receipt.orderTotal(order);

        // bottom
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ width: 1, emphasis: false, data: 'Tak for din bestilling!\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        request += builder.createCutPaperElement({
            feed: true
        });

        this.send(request, statusCallback);

        // if (successCallback !== undefined) {
        //     trader.onReceive = successCallback;
        // } else {
        //     trader.onReceive = () => {}
        // }

        // if (errorCallback !== undefined) {
        //     trader.onError = errorCallback;
        // } else {
        //     trader.onError = () => {}
        // }

        // "fake promise"
        return {
            then: (callback) => {
                // trader.onReceive = callback;
            },
            catch: (callback) => {
                // trader.onError = callback
            }
        }
    },

    print(type, data) {
        switch (type) {
            case 'receipt':
            case 'order':
                return this.order(data);
            case 'giftcard':
            case 'coupon':
                return this.coupon(data);
            case 'test':
                return this.test(data);
            default:
                throw new Error('Unknown action');
        }
    },

    test() {
        let request = builder.createInitializationElement();
        request += Receipt.header();
        request += builder.createCutPaperElement({
            feed: true
        });
        this.send(request);
    },

    giftcard(coupon) {
        return this.coupon(coupon);
    },

    coupon(coupon, statusCallback) {
        if (!coupon['code']) return;

        let request = builder.createInitializationElement();

        // header
        request += Receipt.logo();
        request += builder.createTextElement({ data: '\n' });

        request += Receipt.header();

        // beløb
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ width: 2, height: 2, emphasis: true, data: 'Gavekort\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ width: 1, height: 1, emphasis: false, data: 'Værdi, kr.\n' });
        request += builder.createTextElement({ width: 2, height: 2, emphasis: true, data: coupon.amount + '\n' });

        // udløb
        if (coupon.date_expires) {
            let date_expires = new Date();
            date_expires.setTime(Date.parse(coupon.date_expires));
            request += builder.createTextElement({ data: '\n' });
            request += builder.createTextElement({ width: 1, height: 1, emphasis: false, data: 'Gyldigt indtil ' + formatLeadingZero(date_expires.getDate()) + '-' + formatLeadingZero(date_expires.getMonth()) + '-' + date_expires.getFullYear() + '\n' });
        }

        // besked
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createAlignmentElement({ position: 'left' });
        request += builder.createTextElement({ width: 1, emphasis: false });

        // ...
        if (details !== undefined && details['text'] !== undefined && details['text']['giftcard'] !== undefined && Array.isArray(details['text']['giftcard']) === true) {

            let text = details['text']['giftcard'];
            if (details['text']['giftcard'].length === 0) {
                text = [
                    'Gavekortet kan kun benyttes i den slagter-',
                    'forretning, hvor det er købt - eller på denne',
                    'forretnings webshop. Handler du på web-',
                    'shoppen, skal koden på gavekortet indtastes',
                    'i forbindelse med betaling.',
                    '',
                    'Gavekortet kan ikke ombyttes til kontanter.',
                    'I webshoppen kan gavekortet ikke indløses',
                    'sammen med andre gavekort- eller rabatkoder.',
                ];
            }

            for (let index = 0; index < text.length; index++) {
                request += builder.createTextElement({ data: ' '+ text[index].substring(0, 47) +'\n' });
            }
        }

        /// giftcard

        // request += builder.createTextElement({ data: '                                                \n' });
        // request += builder.createTextElement({ data: ' Gavekortet kan kun benyttes i den slagter-     \n' });
        // request += builder.createTextElement({ data: ' forretning, hvor det er købt - eller på denne  \n' });
        // request += builder.createTextElement({ data: ' forretnings webshop. Handler du på web-        \n' });
        // request += builder.createTextElement({ data: ' shoppen, skal koden på gavekortet indtastes    \n' });
        // request += builder.createTextElement({ data: ' i forbindelse med betaling.                    \n' });
        // request += builder.createTextElement({ data: '\n' });
        // request += builder.createTextElement({ data: ' Gavekortet kan ikke ombyttes til kontanter.    \n' });
        // request += builder.createTextElement({ data: ' I webshoppen kan gavekortet ikke indløses      \n' });
        // request += builder.createTextElement({ data: ' sammen med andre gavekort- eller rabatkoder.   \n' });





        // request += builder.createTextElement({ data: '  Gavekortet kan kun benyttes i forretningen    \n' });
        // request += builder.createTextElement({ data: '  hvor det er købt, eller på forretningens      \n' });
        // request += builder.createTextElement({ data: '  hjemmeside, hvor "gavekortskoden" skal        \n' });
        // request += builder.createTextElement({ data: '  indtastes i "kurven".                         \n' });
        // request += builder.createTextElement({ data: '\n' });
        // request += builder.createTextElement({ data: '  Gavekortet kan ikke ombyttes til kontanter.   \n' });

        // qr kode
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += Receipt.qr(coupon.code);

        // bottom
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({ width: 1, emphasis: false, data: 'Vi glæder os til at se dig!\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });

        request += builder.createCutPaperElement({
            feed: true
        });

        this.send(request, statusCallback);

        // "fake promise"
        return {
            then: (callback) => {
                // trader.onReceive = callback;
            },
            catch: (callback) => {
                // trader.onError = callback
            }
        }
    }
}

// try {
//     let request = builder.createInitializationElement();

//     request += Receipt.header();
//     request += Receipt.qr('https://netkant.com');

//     request += builder.createCutPaperElement({
//         feed: true
//     });

//     this.send(request);
// } catch (e) {
//     return false;
// }

export default StarWebPrint
