export const nl2br = (str, isXhtml) => {
    // Some latest browsers when str is null return and unexpected null value
    if (typeof str === 'undefined' || str === null) {
      return ''
    }
  
    // Adjust comment to avoid issue on locutus.io display
    var breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>'
  
    return (str + '').replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1')
}
