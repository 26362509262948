import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Col, ListGroup, ListGroupItem, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Badge } from "reactstrap";
import { updateOrder } from '../context/actions/orders';
import StarWebPrint from '../services/print';
import { formatDeliveryFullDate } from '../helpers/date';
import { updateLoading, getStatus, printReceipt, registerPrintError, registerPrintSuccess } from '../context/actions/app';
import { nl2br } from '../helpers/string';
import { getWAPFMeta, getPurchaseNote, getDeliveryDate, isRead, getMetaData } from '../helpers/order';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import da from "date-fns/locale/da"; // the locale you want
registerLocale("da", da); // register it with the name you want

export default (props) => {
    const { order } = useSelector(state => state.orders);
    const { printerOnline } = useSelector(state => state.services);
    const [confirm, setConfirm] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);

    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const vatNumber = getMetaData(order, '_billing_company_vat_number');
    const eanNumber = getMetaData(order, '_billing_company_ean_number');



    const markAsRead = () => {
        if (isRead(order))
            return;

        dispatch(updateOrder(order.id, {
            meta_data: [{
                key: "_order_view",
                value: "yes"
            }]
        })).then(() => {
            dispatch(getStatus());
        })
    }

    useEffect(() => {
        if (order) {
            setDeliveryDate(getDeliveryDate(order));

            markAsRead();
        }
    }, [order])



    const dispatch = useDispatch();

    const printOrder = () => {
        updateLoading(true);

        try {
            // StarWebPrint.print('receipt', order).then(res => {
            //     updateLoading(false);
            // });

            const order_id = order.id;

            dispatch(printReceipt(order_id)).then(order => {
                StarWebPrint.receipt(order, (res) => {
                    if (Number(res.status) >= 200 && Number(res.status) < 300 && Number(res.traderCode) === 0) {
                        dispatch(registerPrintSuccess(order_id));
                    } else {
                        dispatch(registerPrintError(order_id, { status: res.status, traderCode: res.traderCode, traderStatus: res.traderStatus }));
                    }
                });
            })
        } catch (e) {
            updateLoading(false);
            alert(e);
        }
    }

    const confirmOrderUpdate = (data) => {
        setConfirm(data)
    }

    const toggleNested = () => {
        setNestedModal(!nestedModal);
        // setCloseAll(false);
    }

    const handleUpdateOrder = (data) => {
        dispatch(updateOrder(order.id, data)).then(order => {
            // ...
            if (order.status === 'completed') props.toggle();

            // ...
            if (!order || order.status !== 'processing') return;

            // ...
            order.meta_data.forEach(item => {
                if (item.key === '_manually_accepted' && item.value === 'yes') {
                    // StarWebPrint.print('receipt', order);
                    StarWebPrint.receipt(order, (res) => {
                        if (Number(res.status) >= 200 && Number(res.status) < 300 && Number(res.traderCode) === 0) {
                            dispatch(registerPrintSuccess(order.id));
                        } else {
                            dispatch(registerPrintError(order.id, { status: res.status, traderCode: res.traderCode, traderStatus: res.traderStatus }));
                        }
                    });
                }
            });

            if (data.status === 'cancelled') {
                toggleModal()
            } else {
                setConfirm(false)
            }
        }).finally(() => {
            if (data.status === 'cancelled') {
                toggleModal()
            } else {
                setConfirm(false)
            }
        });
    }

    const saveDeliveryDate = () => {
        const unixtimestamp = deliveryDate.getTime() / 1000;

        let data = {
            'meta_data': [
                {
                    'key': '_order_delivery',
                    'value': unixtimestamp,
                }
            ]
        }

        dispatch(updateOrder(order.id, data)).finally(() => {
            setNestedModal(!nestedModal);
        });
    }

    // const getStatusText = () => {
    //     switch (order.status) {
    //         case 'completed':
    //             return <Badge color="success">Gennemført</Badge>;
    //         case 'on-hold':
    //             return <Badge color="danger">Afventer</Badge>;
    //         case 'processing':
    //             return <Badge color="warning">Behandler</Badge>;
    //         case 'cancelled':
    //             return <Badge color="danger">Afvist</Badge>;
    //         default:
    //             return 'Ukendt status'
    //     }
    // }

    const getPaidText = () => {
        if (order.date_paid) {
            return <Badge color="success">Betalt</Badge>;
        }

        return <Badge color="danger">Mangler betaling</Badge>;
    }

    const getDeliveryName = () => {
        if (order.shipping_lines.length > 0) {
            return order.shipping_lines[0].method_title;
        } else {
            return 'Ingen leveringsmetode';
        }
    }

    const toggleModal = () => {
        setConfirm(false);

        props.toggle();
    }

    const stripHtml = (html) => {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const getPaymentMessage = (style) => {
        if (!order.date_paid || order.status === "completed")
            return;

        return <p style={style}>Beløbet er reserveret på kreditkotet og trækkes automatisk når ordren gennemføres.</p>
    }

    if (!order) {
        return null;
    }

    let icon = 'receipt'
    if (order.hasOwnProperty('payment_errors') && order.payment_errors.length > 0) {
        icon = 'exclamation-triangle text-danger';
    }

    if (confirm) {
        return (
            <Modal isOpen={order} toggle={toggleModal} className="modal-xl">
                <ModalHeader toggle={toggleModal}><i className={"fa fa-" + icon} style={{ marginRight: '8px' }} />{order.id}</ModalHeader>
                <ModalBody>
                    <h3>Bekræft venligst</h3>
                    {confirm.status === "processing" &&
                        <p>Er du sikker på at acceptere ordren.</p>
                    }
                    {confirm.status === "cancelled" && order.status === 'on-hold' &&
                        <p>Er du sikker på at afvise ordren.</p>
                    }
                    {confirm.status === "cancelled" && order.status === 'processing' &&
                        <p>Er du sikker på at annullere ordren.</p>
                    }
                    {confirm.status === "completed" &&
                        <p>Er du sikker på at gennemføre ordren.</p>
                    }
                    {getPaymentMessage()}
                </ModalBody>
                <ModalFooter style={{ display: 'initial' }}>
                    <ButtonGroup size="lg" className="float-right">
                        <Button color="danger" style={{ width: '200px' }} type="button" onMouseDown={() => { confirmOrderUpdate(false) }}>Nej</Button>
                        <Button color="success" style={{ width: '200px' }} type="button" onMouseDown={() => { handleUpdateOrder(confirm) }}>Ja</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
        )
    }

    return (
        <Modal isOpen={order !== null} toggle={toggleModal} className="modal-xl">
            <ModalHeader toggle={toggleModal}><i className={"fa fa-" + icon} style={{ marginRight: '8px' }} />{order.id}</ModalHeader>
            {order.hasOwnProperty('payment_errors') && order.payment_errors.length > 0 &&
                <div className="alert alert-danger" style={{ borderRadius: 0 }}><ul style={{ marginBottom: 0 }}>{order.payment_errors.map(payment_errors => (
                    <li key={payment_errors.id}>{payment_errors.content}</li>
                ))}</ul></div>
            }
            <ModalBody>
                <Row>
                    <Col>
                        {order.customer_note &&
                            <div className="text-center">
                                <h4>Bemærkning</h4>
                                <div dangerouslySetInnerHTML={{ __html: nl2br(order.customer_note) }}></div>
                                <hr />
                            </div>
                        }
                        <ul>
                            {order.line_items.map((item, index) => {
                                return (
                                    <li key={index}>
                                        {item.quantity} x {item.name}
                                        {getPurchaseNote(item).length > 0 &&
                                            <ul style={{ paddingLeft: 0, listStyle: 'none', marginTop: 10 }}>
                                                <li dangerouslySetInnerHTML={{ __html: nl2br(getPurchaseNote(item)) }}></li>
                                            </ul>
                                        }
                                        {getWAPFMeta(item).length > 0 &&
                                            <ul style={{ paddingLeft: 0, listStyle: 'none', marginTop: 10 }}>
                                                {getWAPFMeta(item).map((meta, _index) => {
                                                    return (<li key={_index}><b>{meta.label}</b><br /><div dangerouslySetInnerHTML={{ __html: stripHtml(meta.value).replace(/\., /g, ".<br />").replace(/\. \(\+kr\.(.*?)\), /, '. (+kr.$1)<br />') }} /></li>)
                                                    // return (<li key={_index}><b>{meta.label}</b><br /><div dangerouslySetInnerHTML={{ __html: stripHtml(meta.value).replaceAll("., ", ".<br />") }} /></li>)
                                                })}
                                            </ul>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    </Col>
                    <Col>
                        <ListGroup flush>
                            <ListGroupItem><b>{getDeliveryName()}:</b><br />{formatDeliveryFullDate(deliveryDate)} {(order.status === 'on-hold' || order.status === 'processing') && <button className="btn float-right" style={{ padding: '0px 10px' }} onMouseDown={toggleNested}><i className="fa fa-calendar-alt" /></button>}</ListGroupItem>
                            {/* <ListGroupItem><b>Status:</b><br /><h4>{getStatusText()}</h4></ListGroupItem> */}
                            {order._number_series !== undefined && order._number_series.map((item, index) => (
                                <ListGroupItem><b>Bestillingsnr. - {item.name}:</b><br />{item.value}</ListGroupItem>
                            ))}
                            <ListGroupItem><b>Betaling:</b><br /><h4>{getPaidText()}</h4> {getPaymentMessage({ fontSize: 12, marginBottom: 0 })}</ListGroupItem>
                            <ListGroupItem><b>Modtaget:</b><br />{formatDeliveryFullDate(new Date(order.date_created))}</ListGroupItem>
                            <ListGroupItem className="billing-info">
                                <b>Kundeoplysninger:</b><br />
                                {order.billing.company &&
                                    <p>{order.billing.company}</p>
                                }
                                {vatNumber &&
                                    <p>CVR: {vatNumber}</p>
                                }
                                {eanNumber &&
                                    <p>EAN: {eanNumber}</p>
                                }
                                {order.shipping.first_name && order.shipping.last_name &&
                                    <p>{order.shipping.first_name} {order.shipping.last_name}</p>
                                }
                                {order.shipping.address_1 &&
                                    <p>{order.shipping.address_1}</p>
                                }
                                {order.shipping.address_2 &&
                                    <p>{order.shipping.address_2}</p>
                                }
                                {order.shipping.postcode && order.shipping.city &&
                                    <p>{order.shipping.postcode} {order.shipping.city}</p>
                                }
                                {order.billing.phone &&
                                    <p>Tlf: {order.billing.phone}</p>
                                }
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                </Row>
                <Modal isOpen={nestedModal} toggle={toggleNested}>
                    <ModalHeader>{getDeliveryName()}</ModalHeader>
                    <ModalBody style={{ textAlign: 'center' }}>
                        <DatePicker onFocus={(e) => { e.currentTarget.blur() }} minDate={new Date()} fixedHeight timeIntervals={15} showTimeSelect timeCaption="Tid" inline locale="da" dateFormat="dd-MM-yyyy" selected={deliveryDate} onChange={date => setDeliveryDate(date)} className="form-control" />
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup size="lg" className="float-right" style={{ width: '100%' }}>
                            <Button color="danger" type="button" onMouseDown={() => { setDeliveryDate(getDeliveryDate(order)); toggleNested(); }}>Annullér ændring</Button>
                            <Button color="success" type="button" onMouseDown={saveDeliveryDate}>Gem ændring</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </ModalBody>
            <ModalFooter style={{ display: 'initial' }}>
                <ButtonGroup size="lg" className="float-left">
                    <Button color="primary" style={{ width: '200px' }} disabled={!printerOnline} onMouseDown={printOrder} >Print</Button>{' '}
                </ButtonGroup>
                {order.status === 'on-hold' &&
                    <ButtonGroup size="lg" className="float-right">
                        <Button color="danger" style={{ width: '200px' }} type="button" onMouseDown={() => { confirmOrderUpdate({ status: 'cancelled' }) }}>Afvis</Button>
                        <Button color="success" style={{ width: '200px' }} type="button" onMouseDown={() => { confirmOrderUpdate({ status: 'processing' }) }}>Accepter</Button>
                    </ButtonGroup>
                }
                {order.status === 'processing' &&
                    <ButtonGroup size="lg" className="float-right">
                        <Button color="danger" style={{ width: '200px' }} type="button" onMouseDown={() => { confirmOrderUpdate({ status: 'cancelled' }) }}>Annuller</Button>
                        <Button color="success" style={{ width: '200px' }} type="button" onMouseDown={() => { confirmOrderUpdate({ status: 'completed' }) }}>Gennemfør</Button>
                    </ButtonGroup>
                }
            </ModalFooter>
        </Modal>
    )
}
