/**
 * This should acctually be a container
 */

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStatus, delayReceipts, disableSound, printReceipt, registerPrintSuccess, registerPrintError, setTest } from '../context/actions/app';
import { setPrinterOnline } from '../context/actions/services';
// import { getOrders } from '../context/actions/orders';
import { Alert } from "reactstrap";
// import { getAuth } from '../context/actions/user';
import { ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import StarWebPrint from '../services/print';
import { sound as pixi } from '@pixi/sound';


export default ({ children }) => {
    const { status, sound, test } = useSelector(state => state.app);
    const { printerOnline } = useSelector(state => state.services);
    const { auth } = useSelector(state => state.user);
    const dispatch = useDispatch();

    // const newProcessingOrderSound = new Audio('/sounds/order-processing.wav');
    // const newOnholdOrderSound = new Audio('/sounds/order-onhold.wav');

    pixi.add('order-processing', '/sounds/order-processing.wav');
    pixi.add('order-onhold', '/sounds/order-onhold.wav');

    const [checkin, setCheckin] = useState(5);
    const [message, setMessage] = useState('');
    // const [debug, setDebug] = useState('');
    // const [test, setTest] = useState(true);
    const [printModal, showPrintModal] = useState(false);
    const [confirmPrintModal, showConfirmPrintModal] = useState(false);


    const bulkPrintReceipts = (queue) => {
        if (!printerOnline) {
            console.error('printer is offline');
            return;
        }

        let promises = [];
        if (status.receipts[queue].length > 0) {
            for (let index = 0; index < status.receipts[queue].length; index++) {
                const order_id = status.receipts[queue][index];

                promises.push(dispatch(printReceipt(order_id)).then(order => {
                    StarWebPrint.receipt(order, (res) => {
                        if (Number(res.status) >= 200 && Number(res.status) < 300 && Number(res.traderCode) === 0) {
                            dispatch(registerPrintSuccess(order_id));
                        } else {
                            dispatch(registerPrintError(order_id, { status: res.status, traderCode: res.traderCode, traderStatus: res.traderStatus }));
                        }
                    });
                }));
            }
        }

        Promise.all(promises).finally(() => {
            // dispatch(getStatus());
        })
    }

    const bulkDelayReceipts = () => {
        if (status.receipts.current.length > 0) {
            dispatch(delayReceipts(status.receipts.current)).then(() => {
                showPrintModal(false);
                // dispatch(getStatus());
            })
        }
    }

    const handleConfirmPrint = () => {
        showConfirmPrintModal(false);
        bulkPrintReceipts('delayed');
    }

    const handleConfirmPrintCurrent = () => {
        showPrintModal(false);
        bulkPrintReceipts('current');
    }

    useEffect(() => {
        // ...
        if (status.receipts.current.length > 0) {
            if (status.checkin > 60 * 30) {
                showPrintModal(true);
            } else if (!printModal) {
                bulkPrintReceipts('current');
            }
        }

        if (sound.newProcessingOrder) {
            pixi.play('order-processing');
            dispatch(disableSound('newProcessingOrder'))
        }

        if (sound.newOnholdOrderSound) {
            pixi.play('order-onhold');
            dispatch(disableSound('newOnholdOrderSound'))
        }

        let interval = undefined;
        if (auth.username && auth.password) {
            interval = setInterval(() => {
                StarWebPrint.ping((res, status) => {
                    if (test) {
                        dispatch(setTest(false));
                        new Promise((resolve, reject) => {
                            if (status) {
                                resolve(status);
                            } else {
                                if (Number(res.traderCode) === 2001) {
                                    reject('Printeren er optaget, prøv venligst at printe igen.');
                                } else {
                                    reject('Printeren er slukket, eller også kunne der ikke oprettes forbindelse.');
                                }
                            }

                            // resolve(Number(res.status) >= 200 && Number(res.status) < 300 && Number(res.traderCode) === 0);
                        }).then(status => {
                            setMessage('');
                            dispatch(setPrinterOnline(status));
                        }).catch(error => {
                            setMessage(error);
                            dispatch(setPrinterOnline(false));
                        }).finally(() => {
                            dispatch(getStatus());
                            dispatch(setTest(true));
                        })
                    } else {
                        // skip
                    }
                });
            }, 1000 * checkin);

            // change "checkin" to 5 minutes (pull new status every 5 min.)
            // setCheckin(5 * 60);
            if (printerOnline) {
                setCheckin(5 * 60);
            } else {
                if (checkin >= 60) {
                    setCheckin(1 * 60);
                } else {
                    setCheckin(5 * 60);
                }
            }

            if (interval) {
                return () => clearInterval(interval);
            }
        }
    }, [ status, auth ]);

    return (
        <>
            {printModal &&
                <Modal isOpen={true} toggle={() => showPrintModal(false)} className="modal-lg">
                    <ModalHeader toggle={() => showPrintModal(false)}>
                        <i className="fa fa-receipt" style={{ marginRight: '8px' }} /> Print nu?
                    </ModalHeader>
                    <ModalBody>
                        <p>Du har {status.receipts.current.length} {status.receipts.current.length > 1 ? 'boner' : 'bon'} der ligger i kø til at blive printet.</p>
                        <p>Vil du printe nu?</p>
                    </ModalBody>
                    <ModalFooter style={{ display: 'initial' }}>
                        <ButtonGroup size="lg" className="float-right">
                            <Button color="danger" style={{ width: '200px' }} type="button" onMouseDown={() => bulkDelayReceipts()}>Nej</Button>
                            <Button color="success" style={{ width: '200px' }} type="button" onMouseDown={handleConfirmPrintCurrent}>Ja</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            }
            {confirmPrintModal &&
                <Modal isOpen={true} toggle={() => showConfirmPrintModal(false)} className="modal-lg">
                    <ModalHeader toggle={() => showConfirmPrintModal(false)}>
                        <i className="fa fa-receipt" style={{ marginRight: '8px' }} /> Print nu?
                    </ModalHeader>
                    <ModalBody>
                        <p>Du har {status.receipts.delayed.length} {status.receipts.delayed.length > 1 ? 'boner' : 'bon'} der venter på at blive printet.</p>
                        <p>Vil du printe nu?</p>
                    </ModalBody>
                    <ModalFooter style={{ display: 'initial' }}>
                        <ButtonGroup size="lg" className="float-right">
                            <Button color="danger" style={{ width: '200px' }} type="button" onMouseDown={() => showConfirmPrintModal(false)}>Nej</Button>
                            <Button color="success" style={{ width: '200px' }} type="button" onMouseDown={() => handleConfirmPrint()}>Ja</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            }
            {status.receipts.delayed.length > 0 &&
                <Alert color="warning" style={{ borderRadius: 0, fontSize: 12, padding: '8px 12px', margin: 0, border: 0 }}>Du har {status.receipts.delayed.length} {status.receipts.delayed.length > 1 ? 'boner' : 'bon'} der venter på at blive printet - <a href="#print" onClick={() => showConfirmPrintModal(true)} onMouseDown={() => showConfirmPrintModal(true)}><b>print nu</b></a></Alert>
            }
            {message !== '' && 
                <Alert color="danger" style={{ borderRadius: 0, fontSize: 12, padding: '8px 12px', margin: 0, border: 0 }}>{message}</Alert>
            }
            {/* {debug && <Alert color="info" style={{ borderRadius: 0, fontSize: 12, padding: '8px 12px', margin: 0, border: 0 }}>{debug}</Alert>} */}
            {children}
        </>
    )
}
