import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getToken, setupData } from '../context/actions/user';

import { Redirect } from "react-router-dom";
// import { StarWebPrintTrader, StarWebPrintBuilder } from 'digitickets.star-web-print'

import { Button, Form, FormGroup, Input, Label, Row, Col, FormText, Alert } from "reactstrap";
import '../styles/pages/login.css'
import Cookies from 'js-cookie'
// import Axios from "axios"



export default () => {
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);

  const { version } = useSelector(state => state.app);

    const dispatch = useDispatch();
    const [ shop, setShop ] = useState(Cookies.get('remember') ? Cookies.getJSON('remember').shop : '');
    const [ username, setUsername ] = useState(Cookies.get('remember') ? Cookies.getJSON('remember').username : '');
    const [ password, setPassword ] = useState('');
    const [ redirect, setRedirect ] = useState('');
    const [ remember, setRemember ] = useState(Cookies.get('remember') ? true : false);
    const [ error, setError ] = useState('');
    // const [ log, setLog ] = useState('');


    // const dispatch = useDispatch();
    // const { auth } = useSelector(state => state.user);
    // const [ redirect, setRedirect ] = useState();

    useEffect(() => {
        let _auth = window.sessionStorage.getItem('auth');
        let _data = window.sessionStorage.getItem('data');

        if (_data && _auth) {
            _auth = JSON.parse(_auth);
            _data = JSON.parse(_data);

            dispatch(setupData(_auth.username, _data)).then(() => {
                setRedirect('/dashboard');
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = e => {
      e.preventDefault();

      setError('');

      if (remember) {
        Cookies.set('remember', {
          username,
          shop
        })
      } else {
        Cookies.remove('remember');
      }

      dispatch(getToken(shop, username, password)).then(res => {
        if (!res.success) {
          setError(res.data);
        } else {
          setRedirect('/dashboard');
        }
      });
    }

    if (redirect) {
      return <Redirect to={redirect} />
    }

    return (
        <Form className="form-login" onSubmit={handleSubmit}>
          <div className="text-center">
            <img alt="mad med mere" src="images/foedevaredanmark-logo.png" width="350" style={{marginBottom: 20, marginTop: 40}} />
            <p style={{fontSize: 10, marginBottom: 40}}>v{version}</p>
          </div>
          { error &&
            <Alert color="danger"><i className="fa fa-exclamation-circle" /> {error}</Alert>
          }
          <FormGroup>
            <Label for="shop">Butikkens domæne:</Label>
            <Input type="text" bsSize="lg" id="shop" onChange={e => setShop(e.target.value)} value={shop} placeholder="" />
            <FormText color="muted">
              F.eks. "slagterjens.dk", "slagterjens.madmedmere.dk" eller "slagterjens.mesterslagteren.dk".
            </FormText>
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label for="username">Brugernavn:</Label>
                <Input type="text" bsSize="lg" id="username" onChange={e => setUsername(e.target.value)} value={username} placeholder="" />
                <FormText color="muted">
                  Kan også være din e-mail.
                </FormText>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="password">Adgangskode:</Label>
                <Input type="password" bsSize="lg" id="password" onChange={e => setPassword(e.target.value)} value={password} placeholder=""  />
                <FormText color="muted">
                    Din selvvalgte adgangskode.
                  </FormText>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup check style={{ textAlign: 'center' }}>
            <Label check>
              <Input onChange={e => setRemember(!remember)} defaultChecked={remember} type="checkbox" />{' '}
              Husk butikkens domæne og brugernavn.
            </Label>
          </FormGroup>
          <Button className="btn-block btn-lg" color="dark" style={{marginTop: 20}} type="submit">Log ind</Button>
        </Form>
    )
}
