import { combineReducers, createStore, applyMiddleware } from "redux";
import services from "./reducers/services";
import coupons from "./reducers/coupons";
import orders from "./reducers/orders";
import user from "./reducers/user";
import app from "./reducers/app";
import thunk from "redux-thunk";

export default createStore(combineReducers({
    app,
    coupons,
    services,
    orders,
    user,
}), applyMiddleware(thunk))
