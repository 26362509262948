import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import Socket from '../services/socket';
import { useHistory } from 'react-router-dom';
import { Navbar, Nav, NavLink, Alert } from "reactstrap";
import { doLogout } from '../context/actions/user';
import StatusBar from "../components/StatusBar";
import { getOrders } from '../context/actions/orders';

import '../styles/layouts/main.css';

export default (props) => {
    const name = useSelector(state => state.user.name);
    const { loading, newOrders } = useSelector(state => state.app);
    const dispatch = useDispatch();
    const history = useHistory();
    // const [redirect, setRedirect] = useState(false);

    

    const logout = () => {
        dispatch(doLogout()).then(() => {
            // Socket.disconnect();
            window.location.reload();
        })
    }

    // if (redirect) {
    //     return <Redirect to={redirect} />
    // }

    return <div>
        <div className={`system-backdrop modal-backdrop show d-${(loading ? 'flex' : 'none')} align-items-center justify-content-center`}>
            <i className="fa fa-spin fa-spinner" style={{ color: '#FFF', fontSize: '30' }} />
        </div>


        <Navbar color="light" className="sticky-top" light>
            <Nav>
                <NavLink className="btn-lg" onMouseDown={() => { history.push("/dashboard") }}><i className="fa fa-th-large" style={{ marginRight: '8px' }} />Menu</NavLink>
            </Nav>
            <Nav className="name">
                <li>{name}</li>
            </Nav>
            <Nav horizontal="end">
                <NavLink className="btn-lg" onMouseDown={logout}>Log ud<i className="fa fa-sign-out-alt" style={{ marginLeft: '12px' }} /></NavLink>
            </Nav>
        </Navbar>
        { newOrders && 
            <Alert style={{ backgroundColor: '#28a745', lineHeight: '22px', fontWeight: 'bold', color: '#FFFFFF', borderRadius: 0, fontSize: 12, padding: '8px 12px', margin: 0, border: 0 }}>Der er kommet ny(e) ordre. <a href="#reload" onMouseDown={props.onReload ? props.onReload : () => dispatch(getOrders())} className="badge badge-light" style={{fontSize: 12, float: 'right', padding: '5px 8px'}}>opdatér ordre</a></Alert>
        }
        <div style={{marginTop: 30}}>
            {props.children}
        </div>
        <StatusBar />
    </div>
}
