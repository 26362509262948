import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Alert, FormGroup, InputGroupAddon, Label, FormText, InputGroup, Col, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Form, ListGroup, ListGroupItem, Badge } from "reactstrap";
import { updateCoupon } from '../context/actions/coupons';
import { updateLoading } from '../context/actions/app';
import StarWebPrint from '../services/print';
import { nl2br } from '../helpers/string';
import { formatLeadingZero } from '../helpers/number';

export default (props) => {
    const { coupon } = useSelector(state => state.coupons);
    const amountInput = useRef(null);
    const { printerOnline } = useSelector(state => state.services);
    const [ amount, setAmount ] = useState('');
    const [ confirm, setConfirm ] = useState(false);
    const [ action, setAction ] = useState('withdraw');
    const dispatch = useDispatch();

    if (!coupon) {
        return null;
    }

    // ...
    const depositAmount = parseFloat(coupon.amount) + parseFloat(amount);
    const excessAmount  = parseFloat(coupon.amount) - parseFloat(amount);
    const newAmount     = (action === 'withdraw') ? (excessAmount < 0 ? 0 : excessAmount) : depositAmount ;
    const apiAmount     = (action === 'withdraw') ? (excessAmount < 0 ? coupon.amount : amount) : depositAmount ;
    
    /**
     * 
     */
    const printCoupon = () => {
        updateLoading(true);
        
        try {
            StarWebPrint.print('coupon', coupon).then(res => {
                updateLoading(false);
            });
        } catch (e) {
            updateLoading(false);
            alert(e);
        }
    }

    /**
     * 
     * @param {*} e 
     */
    const handleModalSubmit = e => {
        e.preventDefault();

        if (!confirm) return;

        dispatch(updateCoupon(action, coupon.id, {
            code: coupon.code,
            amount: String(apiAmount),
        })).then(() => {
            setAmount('');
            setConfirm(false);
        });
    }

    // ...
    const formatDateExpires = () => {
        if (!coupon.date_expires) {
            return 'Aldrig';
        }

        const today = new Date();
        let dateExpires = new Date();
        dateExpires.setTime(Date.parse(coupon.date_expires));

        return <h5><Badge className="text-medium" color={(dateExpires < today) ? 'danger' : 'success'}>{formatLeadingZero(dateExpires.getDate()) +'-'+ formatLeadingZero(dateExpires.getMonth()) +'-'+ dateExpires.getFullYear()}</Badge></h5>;
    }

    return (
        <Modal isOpen={coupon} onOpened={() => { amountInput.current.focus() }} toggle={props.toggle} className="modal-xl">
            <ModalHeader toggle={props.toggle}><i className="fa fa-gift" style={{marginRight: '8px', marginBottom: '1px'}} />{coupon.code}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        { action === 'withdraw' && excessAmount < 0 &&
                            <Alert className="text-center" color="danger" fade={false}>
                                <b>BEMÆRK!</b><br /> Du har indtastet et beløb som er <b>kr. {(excessAmount*-1).toFixed(2)}</b> højere end værdien af gavekortet.
                            </Alert>
                        }
                        <ListGroup flush>
                            <ListGroupItem><b>Saldo</b><br />kr. {coupon.amount}</ListGroupItem>
                            <ListGroupItem><b>Udløber</b><br /> {formatDateExpires()}</ListGroupItem>
                            { coupon.description && 
                                <ListGroupItem><b>Beskrivelse</b><br />{nl2br(coupon.description)}</ListGroupItem>
                            }
                            {/* <ListGroupItem><b>Anvendt</b><br />{coupon.usage_count} {coupon.usage_count === 1 ? 'gang' : 'gange'}</ListGroupItem> */}
                        </ListGroup>
                    </Col>
                    <Col>
                    <Form onSubmit={e => { e.preventDefault(); amountInput.current.blur(); }}>
                        <ListGroup className="text-center font-weight-bold" style={{marginBottom: '30px'}} horizontal>
                            <ListGroupItem style={action !== 'withdraw' ? { backgroundColor: '#efefef' } : {}} active={action === 'withdraw'} onMouseDown={() => { setAction('withdraw') }}>Træk beløb på gavekort</ListGroupItem>
                            <ListGroupItem style={action !== 'deposit' ? { backgroundColor: '#efefef' } : {}} active={action === 'deposit'} onMouseDown={() => { setAction('deposit') }}>Indsæt beløb på gavekort</ListGroupItem>
                        </ListGroup>
                        <FormGroup style={{marginBottom: '30px'}}>
                            <Label for="order_id">Beløb:</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">kr.</InputGroupAddon>
                                <input type="number" step=".01" name="order_id" className="form-control-lg form-control" bsSize="lg" value={amount} onChange={e => { setAmount(e.target.value) }} autoComplete="off" ref={amountInput} />
                            </InputGroup>
                            <FormText color="muted">
                                Indtast det ønskede beløb, inklusiv moms.
                            </FormText>
                        </FormGroup>
                        { amount && 
                            <FormGroup check>
                                <div className="custom-checkbox custom-control custom-control-inline" style={{marginBottom: '30px'}}>
                                    <input type="checkbox" id="exampleCustomInline" className="custom-control-input" onChange={e => { setConfirm(!confirm) }} checked={confirm} />
                                    <label className="custom-control-label" for="exampleCustomInline">Bekræft at du ønsker at <b>{action === 'withdraw' ? 'trække' : 'indsætte' } kr. {parseFloat(amount).toFixed(2)}</b> på gavekortet og at den nye saldo bliver <b>kr. {newAmount.toFixed(2)}</b></label>
                                </div>
                            </FormGroup>
                        }
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter style={{display: 'initial'}}>
                    <ButtonGroup size="lg" className="float-left">
                        <Button color="primary" style={{ width: '200px' }} onMouseDown={printCoupon} disabled={!printerOnline}>Print</Button>{' '}
                    </ButtonGroup>
                    <ButtonGroup size="lg" className="float-right">
                        { amount && confirm && 
                            <Button color="success" style={{ width: '200px' }} type="button" onMouseDown={handleModalSubmit}>{action === 'withdraw' ? 'Træk' : 'Indsæt' } beløb</Button>
                        }
                    </ButtonGroup>
                </ModalFooter>
        </Modal>
    )
}
