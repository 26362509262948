// export const getDeliveryDate = (order) => {
//     let deliveryDate;
//     order.meta_data.forEach(item => {
//         if (item.key === '_order_delivery') {
//             deliveryDate = new Date(Number(item.value) * 1000);
//         }
//     });

//     return deliveryDate;
// }

export const getDeliveryDate = (order) => {
    const metaValue = getMetaData(order, '_order_delivery')
    if (metaValue) {
        return new Date(Number(metaValue) * 1000);
    }
    // if (order['meta_data'] !== undefined) {
    //     const entries = Object.entries(order.meta_data);
    //     for (let index = 0; index < entries.length; index++) {
    //         const entry = entries[index];
    //         if (entry[1].key === '_order_delivery') {
    //             return new Date(Number(entry[1].value) * 1000);
    //         }
    //     }
    // }

    return 0;
}

export const getMetaData = (order, name) => {
    if (!order) {
        return false;
    }

    if (order['meta_data'] !== undefined) {
        const entries = Object.entries(order.meta_data);
        for (let index = 0; index < entries.length; index++) {
            const entry = entries[index];
            if (entry[1].key === name) {
                return entry[1].value;
            }
        }
    }

    return false;
}

export const getWAPFMeta = (item) => {
    // ...
    let meta = [];
    item.meta_data.forEach(_item => {
        if (_item.key === '_wapf_meta') {
            if (typeof _item.value === "string") {
                meta = _item.value;
            } else if (typeof _item.value === "object" && _item.value.hasOwnProperty('fields')) {
                if (_item.value.fields.length !== undefined) {
                    meta = _item.value.fields;
                } else {
                    meta = Object.values(_item.value.fields);
                }
            }
        }
    })

    return meta;
}

export const getPurchaseNote = (item) => {
    const meta_data = item.meta_data;
    for (var i = 0; i < meta_data.length; i++) {
        if (meta_data[i].key === '_purchase_note') {
            return meta_data[i].value
        }
    }

    return '';
}


export const isRead = (order) => {
    for (let index = 0; index < order.meta_data.length; index++) {
        const meta_data = order.meta_data[index];
        if (meta_data.key === '_order_view' && meta_data.value === 'yes') {
            return true;
        }
    }

    return false;
}
