/**
 * This should acctually be a container
 */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUpdate } from '../context/actions/app';
import { Alert } from "reactstrap";


export default ({ children }) => {
    const { update } = useSelector(state => state.app);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUpdate())

        setInterval(() => {
            dispatch(getUpdate())
        }, 60000 * 5)
    }, [ dispatch ]);

    if (!update)
        return children;

    return (
        <div>
            { update &&
                <Alert color="info" style={{ borderRadius: 0, fontSize: 12, padding: '8px 12px', margin: 0, border: 0 }}>Der er en nyere version tilgængelig (<b>{update}</b>) - <a href="#update" onClick={() => window.location.reload()} onMouseDown={() => window.location.reload()}><b>opdatér nu</b></a>.</Alert>
            }
            {children}
        </div>
    )
}
