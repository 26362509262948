/**
 * 
 * @param {*} online 
 */
export const setSocketOnline = online => {
    return dispatch => {
        dispatch(updateSocketOnline(online));
    }
}

/**
 * 
 * @param {*} online 
 */
export const setPrinterOnline = online => {
    return dispatch => {
        dispatch(updatePrinterOnline(online));
    }
}

/**
 * 
 * @param {*} online 
 */
 export const setApiOnline = online => {
    return dispatch => {
        dispatch(updateApiOnline(online));
    }
}

const updateSocketOnline = data => {
    return { type: 'UPDATE_SOCKET_ONLINE', data }
}

const updatePrinterOnline = data => {
    return { type: 'UPDATE_PRINTER_ONLINE', data }
}

const updateApiOnline = data => {
    return { type: 'UPDATE_API_ONLINE', data }
}
