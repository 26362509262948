import Axios from "axios"
import { updateLoading } from './app';
import settings from '../../settings';
// import { getAuth } from './user';
/**
 *
 * @param {*} q
 */
export const searchCoupons = q => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        return Axios.get(`${settings.API_HOST}/coupons?per_page=15&meta_key=giftcard&search=${q}`, {
            auth: getState().user.auth
        }).then(res => {
            dispatch(setSearchResults(res.data.data));
            dispatch(updateLoading(false));
        }).catch(res => {
            dispatch(updateLoading(false));
        })
    }
}

/**
 *
 * @param {*} q
 */
export const createCoupon = data => {
    return async (dispatch, getState) => {
        dispatch(updateLoading(true));
        return await Axios.post(`${settings.API_HOST}/coupons`, data, {
            auth: getState().user.auth
        }).then(res => {
            dispatch(updateLoading(false));
            return res.data.data;
        }).catch(res => {
            dispatch(updateLoading(false));
        })
    }
}


/**
 *
 * @param {*} coupon
 */
export const setSelectedCoupon = coupon => {
    return (dispatch, getState) => {
        dispatch(setSelectedCouponAction(coupon));
    }
}

/**
 *
 * @param {*} action
 * @param {*} id
 * @param {*} data
 */
export const updateCoupon = (action, id, data) => {
    return (dispatch, getState) => {
        return Axios.put(`${settings.API_HOST}/coupons/${id}/${action}`, data, {
            auth: getState().user.auth
        }).then(res => {
            dispatch(setSelectedCouponAction(res.data.data));
            dispatch(setCouponInLists(res.data.data));
            // dispatch(setOrderLoading(false));
        })
    }
}

const setSearchResults = data => {
    return { type: 'SET_COUPON_SEARCH_RESULTS', data }
}

const setCouponInLists = data => {
    return { type: 'SET_COUPON_IN_LISTS', data }
}

const setSelectedCouponAction = data => {
    return { type: 'SET_SELECTED_COUPON', data }
}
