import Axios from "axios"
import settings from '../../settings';
import { setApiOnline } from './services';

/**
 *
 * @param {*} q
 */
export const getManifest = () => {
    return (dispatch, getState) => {
        return Axios.get(`/manifest.json`).then(res => {
            dispatch(setManifest(res.data));
            return res.data;
        })
    }
}

export const getStatus = () => {
    return (dispatch, getState) => {
        const auth = getState().user.auth;
        if (!auth.username || !auth.password) {
            return Promise.all([]);
        }

        return Axios.get(`${settings.API_HOST}/fd-orders/status`, {
            auth
        }).then(res => {
            dispatch(setStatus(res.data.data));
            dispatch(setApiOnline(true));
            return res.data.data;
        }).catch(res => {
            dispatch(setApiOnline(false));
        });
    }
}

export const delayReceipts = (ids) => {
    return async (dispatch, getState) => {
        return await Axios.post(`${settings.API_HOST}/fd-orders/receipts-delay`, { ids }, {
            auth: getState().user.auth
        })
    }
}

/**
 *
 */
export const printReceipt = (id) => {
    return (dispatch, getState) => {
        return Axios.post(`${settings.API_HOST}/fd-orders/receipts-print/${id}`, {}, {
            auth: getState().user.auth
        }).then(res => {
            console.log(res.data);
            return res.data.data
        })
    }
}

/**
 *
 */
 export const registerPrintSuccess = (id) => {
    return (dispatch, getState) => {
        return Axios.post(`${settings.API_HOST}/fd-orders/receipts-print-success/${id}`, {}, {
            auth: getState().user.auth
        }).then(res => {
            return true
        })
    }
}

/**
 *
 */
 export const registerPrintError = (id, data) => {
    return (dispatch, getState) => {
        return Axios.post(`${settings.API_HOST}/fd-orders/receipts-print-error/${id}`, data, {
            auth: getState().user.auth
        }).then(res => {
            return true
        })
    }
}


export const setTest = (data) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_TEST', data });
    }
}

export const disableSound = (data) => {
    return (dispatch, getState) => {
        dispatch({ type: 'DISABLE_SOUND', data });
    }
}


export const getUpdate = () => {
    return (dispatch, getState) => {
        return Axios.get(`/manifest.json?${new Date().getTime()}`).then(res => {
            if (res.data['version'] && res.data.version !== getState().app.version) {
                dispatch(setUpdate(res.data.version));
            }
        })
    }
}

/**
 *
 * @param {*} loading
 */
export const updateLoading = loading => {
    return (dispatch, getState) => {
        dispatch(setLoading(loading));
    }
}

/**
 *
 * @param {*} data
 */
const setManifest = data => {
    return { type: 'SET_MANIFEST', data }
}

/**
 *
 * @param {*} data
 */
 const setStatus = data => {
    return { type: 'SET_STATUS', data }
}

/**
 *
 * @param {*} data
 */
const setLoading = data => {
    return { type: 'SET_LOADING', data }
}


/**
 *
 * @param {*} data
 */
const setUpdate = data => {
    return { type: 'SET_UPDATE', data }
}

/**
 *
 * @param {*} data
 */
export const setNewOrders = data => {
    return { type: 'SET_NEW_ORDERS', data }
}
