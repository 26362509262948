import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import Layout from '../layouts/Main'
import { Container, CardDeck, CardBody, CardTitle } from 'reactstrap';
import CouponCreateModal from '../components/CouponCreateModal';
import { useSelector } from 'react-redux';

export default () => {
    const { status } = useSelector(state => state.app);

    const [ displayCouponCreateModal, setDisplayCouponCreateModal ] = useState(false);
    const history = useHistory();

    const showStatus = () => {
        if (status.checkin === 0) {
            return;
        }

        return <p>({status.orders.processing.length + status.orders.onhold.length} nye)</p>
    }
    
    return <Layout>
        <Container className="dashboard">
            <CardDeck style={{marginTop: '30px', height: '230px'}}>
                <button onMouseDown={() => { history.push("/orders/search") }} className="card">
                    <CardBody className="w-100 align-items-center text-center d-flex justify-content-center">
                        <CardTitle>Find <br />ordre</CardTitle>
                    </CardBody>
                </button>
                <button onMouseDown={() => { history.push("/orders") }} className="card">
                    <CardBody className="w-100 align-items-center text-center d-flex justify-content-center">
                        <CardTitle>Vis ordre{showStatus()}</CardTitle>
                    </CardBody>
                </button>
                <button onMouseDown={() => { history.push("/cal") }} className="card">
                    <CardBody className="w-100 align-items-center text-center d-flex justify-content-center">
                        <CardTitle className="d-block-inline">Ordre <br />kalender</CardTitle>
                    </CardBody>
                </button>
            </CardDeck>
            <CardDeck style={{marginTop: '30px', height: '230px'}}>
                <button onMouseDown={() => { history.push("/coupons/search") }} className="card">
                    <CardBody className="w-100 align-items-center text-center d-flex justify-content-center">
                        <CardTitle className="d-block-inline">Find gavekort</CardTitle>
                    </CardBody>
                </button>
                <button onMouseDown={() => { setDisplayCouponCreateModal(true) }} className="card">
                    <CardBody className="w-100 align-items-center text-center d-flex justify-content-center">
                        <CardTitle>Opret gavekort</CardTitle>
                    </CardBody>
                </button>
                {/* <Card>
                    <CardBody>
                        <CardTitle><b>Vis gavekort</CardTitle>
                        <CardText>...</CardText>
                    </CardBody>
                </Card> */}
            </CardDeck>
        </Container>
        <CouponCreateModal isOpen={displayCouponCreateModal} toggle={() => { setDisplayCouponCreateModal(false) }} />
    </Layout>
}
