import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Container, Nav, NavItem, NavLink, Input } from "reactstrap";
import { getOrder, getOrders, setSelectedOrder } from '../../context/actions/orders';
import Layout from '../../layouts/Main'
import OrderCardDeck from "../../components/OrderCardDeck";
import OrderModal from "../../components/OrderModal";

/**
 * /orders
 */
export default () => {
    const { orders } = useSelector(state => state.orders);
    const dispatch = useDispatch();

    const orderFilter = JSON.parse(window.sessionStorage.getItem('order-index-filter'));

    const _defaultShowZeroToOne = orderFilter ? (orderFilter['showZeroToOne'] ?? true) : true;
    const _defaultShowOneToThree = orderFilter ? (orderFilter['showOneToThree'] ?? true) : true;
    const _defaultShowAboveThree = orderFilter ? (orderFilter['showAboveThree'] ?? true) : true;
    const _defaultShowOnHold = orderFilter ? (orderFilter['showOnHold'] ?? true) : true;

    const [ showZeroToOne, setShowZeroToOne ] = useState(_defaultShowZeroToOne);
    const [ showOneToThree, setShowOneToThree ] = useState(_defaultShowOneToThree);
    const [ showAboveThree, setShowAboveThree ] = useState(_defaultShowAboveThree);
    const [ showOnHold, setShowOnHold ] = useState(_defaultShowOnHold);

    const getShowStatus = () => {
        let status = [];

        if (showZeroToOne || showOneToThree || showAboveThree) {
            status.push('wc-processing');
        }
        
        if (showOnHold) {
            status.push('wc-on-hold');
        }

        if (status.length === 0) {
            return [ 'none' ];
        }

        return status.join(',')
    }

    const getDeliveryInterval = () => {
        if (showOnHold && (!showZeroToOne && !showOneToThree && !showAboveThree)) {
            return '';
        }

        let first = 0;
        let last  = 0;
        let now   = Math.floor(Date.now() / 1000);

        if (showAboveThree) {
            first = now + (3600 * 3);
            last  = now + (86400 * 30 * 6);
        }

        if (showOneToThree) {
            first = now + (3600 * 1);
        }

        if (showOneToThree && !last) {
            last  = now + (3600 * 3);
        }

        if (showZeroToOne) {
            first = now - (86400 * 30 * 6);
        }

        if (showZeroToOne && !last) {
            last  = now + (3600 * 1);
        }

        if (first && last) {
            return first +':'+ last;
        }

        return '';
    }

    


    useEffect(() => {
        dispatch(getOrders({
            status: getShowStatus(),
            between: getDeliveryInterval(),
        }))

        window.sessionStorage.setItem('order-index-filter', JSON.stringify({
            showZeroToOne,
            showOneToThree,
            showAboveThree,
            showOnHold
        }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ showZeroToOne, showOneToThree, showAboveThree, showOnHold ])

    return (
        <Layout>
            <Container className="ptr">
                <Nav fill style={{ marginBottom: 20 }}>
                    <NavItem style={{ width: '25%' }}>
                        <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-danger"><Input type="checkbox" onChange={e => { setShowZeroToOne(!showZeroToOne) }} checked={showZeroToOne} /><span>0-1 time til levering</span></label></NavLink>
                    </NavItem>
                    <NavItem style={{ width: '25%' }}>
                        <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-warning"><Input type="checkbox" onChange={e => { setShowOneToThree(!showOneToThree) }} checked={showOneToThree} /><span>1-3 timer til levering</span></label></NavLink>
                    </NavItem>
                    <NavItem style={{ width: '25%' }}>
                        <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-success"><Input type="checkbox" onChange={e => { setShowAboveThree(!showAboveThree) }} checked={showAboveThree} /><span>Over 3 timer til levering</span></label></NavLink>
                    </NavItem>
                    <NavItem style={{ width: '25%' }}>
                        <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-light"><Input type="checkbox" onChange={e => { setShowOnHold(!showOnHold) }} checked={showOnHold} /><span>Afventer</span></label></NavLink>
                    </NavItem>
                </Nav>
                {/* <OrderCardDeck orders={orders} onMouseDown={order => {  dispatch(setSelectedOrder(order)) }} /> */}
                <OrderCardDeck orders={orders} onMouseDown={order => {  dispatch(getOrder(order.id)) }} />
                <OrderModal toggle={() => { dispatch(setSelectedOrder(false)) }} />
            </Container>
        </Layout>
    )
}
