const initialState = {
    version: '1.6.16',
    update: false,
    test: true,
    newOrders: false,
    manifest: null,
    loading: false,
    sound: {
        newProcessingOrder: false,
        newOnholdOrder: false,
    },
    status: {
        checkin: 0,
        orders: {
            processing: [],
            onhold: [],
        },
        receipts: {
            current: [],
            delayed: [],
        }
    },
    
}

export default (state = initialState, action) => {
    let sound = state.sound;
    let newOrders = state.newOrders;
    switch (action.type) {
        case 'SET_MANIFEST':
            return { ...state, manifest: action.data };
        case 'SET_TEST':
            return { ...state, test: action.data };
        case 'SET_NEW_ORDERS':
            return { ...state, newOrders: action.data };
        case 'DISABLE_SOUND':
            sound[action.data] = false;
            return { ...state, sound };
        case 'SET_STATUS':
                if (state.status.checkin > 0) {
                    if (action.data.orders.processing.length > 0) {
                        // check for new processing orders
                        for (let index = 0; index < action.data.orders.processing.length; index++) {
                            if (!state.status.orders.processing.includes(action.data.orders.processing[index])) {
                                sound.newProcessingOrder = true;
                                newOrders = true;
                                break;
                            }
                        }
                    }

                    if (action.data.orders.onhold.length > 0) {
                        // check for new onhold orders
                        for (let index = 0; index < action.data.orders.onhold.length; index++) {
                            if (!state.status.orders.onhold.includes(action.data.orders.onhold[index])) {
                                sound.newOnholdOrder = true;
                                newOrders = true;
                                break;
                            }
                        }
                    }
                }

                return { ...state, status: action.data, sound, newOrders };
        case 'SET_LOADING':
            return { ...state, loading: action.data };
        case 'SET_UPDATE':
            return { ...state, update: action.data };
        default:
            return state;
    }
}
