const initialState = {
    printerOnline: false,
    socketOnline: false,
    apiOnline: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_API_ONLINE':
            return { ...state, apiOnline: action.data };
        case 'UPDATE_SOCKET_ONLINE':
            return { ...state, socketOnline: action.data };
        case 'UPDATE_PRINTER_ONLINE':
            return { ...state, printerOnline: action.data };
        default:
            return state;
    }
}
