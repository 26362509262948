import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from "react-router-dom";

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Cal from './pages/orders/Calendar'
import UpdateChecker from './containers/UpdateChecker'
import OrderIndex from './pages/orders/Index'
import OrderSearch from './pages/orders/Search'
import CouponSearch from './pages/coupons/Search'
import moment from 'moment'
import 'moment/locale/da';
import { useSelector } from 'react-redux';
import StatusChecker from './containers/StatusChecker';

moment.locale('da');

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
    const { auth } = useSelector(state => state.user);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                (auth.username && auth.password) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}


function App() {
    return (
        <StatusChecker>
            <UpdateChecker>
                <Switch>
                    <PrivateRoute path="/coupons/search">
                        <CouponSearch />
                    </PrivateRoute>
                    <PrivateRoute path="/orders/search">
                        <OrderSearch />
                    </PrivateRoute>
                    <PrivateRoute path="/orders">
                        <OrderIndex />
                    </PrivateRoute>
                    <PrivateRoute path="/dashboard">
                        <Dashboard />
                    </PrivateRoute>
                    <PrivateRoute path="/cal">
                        <Cal />
                    </PrivateRoute>
                    <Route path="/">
                        <Login />
                    </Route>
                </Switch>
            </UpdateChecker>
        </StatusChecker>
    )
}

export default App;
