import React from 'react';
import OrderCard from './OrderCard'
import { useSelector } from "react-redux"
import { CardDeck } from 'reactstrap';

export default (props) => {
    const { loading } = useSelector(state => state.app);

    if (loading) {
        return (
            <div><i className="fa fa-spinner fa-spin" /> Indlæser ordre...</div>
        )
    }
    
    return (
        <CardDeck>
            {props.orders.map((order, index) => { return (
                <div key={index} className="col-6 col-md-4 col-xs-6">
                    <OrderCard view={props.view} order={order} onMouseDown={() => { props.onMouseDown(order) }} />
                </div>
            ) })}
        </CardDeck>
    )
}
