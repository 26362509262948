import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
// import { useSelector } from "react-redux"
import { formatDeliveryFullDate } from '../helpers/date';
import { isRead } from '../helpers/order';

export default (props) => {

    /**
     *
     * @param {*} order
     */
    const getDeliveryDate = (order) => {
        let deliveryDate;
        order.meta_data.forEach(item => {
            if (item.key === '_order_delivery') {
                deliveryDate = new Date(Number(item.value) * 1000);
            }
        });

        return deliveryDate;
    }

    const orderStyle2 = (order, style) => {

        // ...
        if (isRead(order))
            return style;

        // ...
        return { ...style,
            borderWidth: 2,
            borderColor: "black"
        };
    }

    /**
     *
     * @param {*} order
     */
    const getHoursLeft = (order) => {
        const deliveryDate = getDeliveryDate(order);

        if (deliveryDate)
            return ((deliveryDate.getTime() - (new Date()).getTime()) / (60 * 60 * 1000)).toFixed(2);

        return false;
    }

    /**
     *
     * @param {*} order
     * @param {*} style
     */
    const orderStyle = (order, style) => {
        if (props.view === 'search') {
            return searchOrderStyle(order, style);
        }

        switch (order.status) {
            case 'on-hold':
                return { ...style,
                    backgroundColor: '#f8f9fa', // grey
                    color: 'black',
                };
            case 'processing':
                // ...
                let hours_left = getHoursLeft(order);

                if (hours_left <= 1) {
                    return { ...style,
                        backgroundColor: '#f8d7da', // red
                        color: 'black',
                    };
                } else if (hours_left <= 3) {
                    return { ...style,
                        backgroundColor: '#fff3cd', // yellow
                        color: 'black',
                    };
                }

                return { ...style,
                    backgroundColor: '#d4edda', // green
                    color: 'black',
                };
            default:
                return { ...style,
                    backgroundColor: '#ffffff', // white
                    color: 'black',
                };
        }
    }

    /**
     *
     * @param {*} order
     * @param {*} style
     */
    const searchOrderStyle = (order, style) => {
        switch (order.status) {
            case 'on-hold':
                return { ...style,
                    backgroundColor: '#f8f9fa', // grey
                    color: 'black',
                };
            case 'cancelled':
                return { ...style,
                    backgroundColor: '#f8d7da', // red
                    color: 'black',
                };
            case 'processing':
                return { ...style,
                    backgroundColor: '#fff3cd', // yellow
                    color: 'black',
                };
            case 'completed':
                return { ...style,
                    backgroundColor: '#d4edda', // green
                    color: 'black',
                };
            default:
                return { ...style,
                    backgroundColor: '#ffffff', // white
                    color: 'black',
                };
        }
    }

    const renderDataList = () => {



        let items = [];

        if (props.order.shipping_lines.length > 0) {
            items.push(
            <dl key={0}>
                <dt>{props.order.shipping_lines[0].method_title}</dt>
                <dd>{formatDeliveryFullDate(getDeliveryDate(props.order))}</dd>
            </dl>
            )
        } else {
            items.push(
            <dl key={0}>
                <dt>Ingen leveringsmetode</dt>
                <dd><i>Ingen leveringsdato</i></dd>
            </dl>
            );
        }

        return items;
    }

    let icon = 'receipt'
    if (props.order.hasOwnProperty('payment_errors') && props.order.payment_errors.length > 0) {
        icon = 'exclamation-triangle text-danger';
    }

    return (
        <Card style={orderStyle2(props.order, orderStyle(props.order, { margin: '0px 0px 30px 0px', minHeight: '145px', cursor: 'pointer' }))}>
            <CardBody onMouseDown={() => { props.onMouseDown(props.order) }}>
                <CardTitle>
                    <i className={"fa fa-"+ icon} style={{marginRight: '8px'}} /><b>{props.order.number}</b>
                    {props.order.customer_note &&
                        <i className="fa fa-comment float-right" style={{marginLeft: '8px'}} />
                    }
                </CardTitle>
                { renderDataList(props.order) }
            </CardBody>
        </Card>
    )
}
