import React, { useEffect, useState, useRef } from "react";
import { searchCoupons, setSelectedCoupon } from '../../context/actions/coupons';
import Layout from '../../layouts/Main'
import { Form, FormGroup, Label, Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import CouponCardDeck from '../../components/CouponCardDeck';
import CouponModal from '../../components/CouponModal';

/**
 * orders index
 */
export default () => {
    const { searchResults } = useSelector(state => state.coupons);
    const [ searchQuery, setSearchQuery ] = useState('');
    const [ searchTimeout, setSearchTimeout ] = useState('');
    // const [ inputType, setInputType ] = useState('number');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(searchCoupons('')).finally(() => {

        });
    }, [ dispatch ]);


    const searchInput = useRef(null);

    const handleSearch = query => {
        dispatch(searchCoupons(query)).finally(() => {

        });
    }

    const handleSearchSubmit = e => {
        e.preventDefault();
        searchInput.current.blur();
        handleSearch(searchQuery);
    };

    const handleChangeQuery = e => {
        if (searchTimeout) clearTimeout(searchTimeout);

        const query = e.target.value;
        setSearchQuery(query);

        if (query.length >= 3) {
            setSearchTimeout(setTimeout(() => {
                handleSearch(query);
            }, 500));
        }
    }
    
    useEffect(() => {
        searchInput.current.focus();
    }, []);

    return (
        <Layout>
            <Container>
                <Form onSubmit={handleSearchSubmit}>
                    <FormGroup style={{marginBottom: '30px'}}>
                        <Label for="order_id">Gavekort søgning:</Label>
                        <input type="text" name="coupon_id" className="form-control-lg form-control" onChange={handleChangeQuery} value={searchQuery} ref={searchInput} autoComplete="off" />
                    </FormGroup>
                </Form>
                <CouponCardDeck coupons={searchResults} onMouseDown={coupon => { dispatch(setSelectedCoupon(coupon)) }} />
                <CouponModal toggle={() => { dispatch(setSelectedCoupon(null)) }} />
            </Container>
        </Layout>
    )   
}
