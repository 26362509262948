import React from 'react';
import CouponCard from './CouponCard'
import { useSelector } from "react-redux"
import { CardDeck } from 'reactstrap';

export default (props) => {
    const { loading } = useSelector(state => state.app);

    if (loading) {
        return (
            <div><i className="fa fa-spinner fa-spin" /> Søger efter gavekort, vent venligst ...</div>
        )
    }
    
    return (
        <CardDeck>
            {props.coupons.map((coupon, index) => { return (
                <div key={index} className="col-6 col-md-4 col-xs-6">
                    <CouponCard coupon={coupon} onMouseDown={() => { props.onMouseDown(coupon) }} />
                </div>
            ) })}
        </CardDeck>
    )
}
