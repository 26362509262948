import React from 'react';
import { formatLeadingZero } from './number';

export const formatDeliveryFullDate = (date) => {
    if (date > 0)
        return formatDeliveryDate(date) +' '+ formatDeliveryTime(date);

    return <i>Ukendt leveringsdato</i>;
}

export const formatDeliveryTime = (date) => {
    if (date)
        return 'kl. '+ formatLeadingZero(date.getHours()) +':'+ formatLeadingZero(date.getMinutes());

    return <i>Ukendt leveringstidspunkt</i>;
}

export const formatDeliveryDate = (date) => {
    if (date)
        return formatLeadingZero(date.getDate()) +'-'+ formatLeadingZero(date.getMonth() + 1) +'-'+ date.getFullYear();

    return <i>Ukendt leveringsdato</i>;
}
