import React from "react";
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem } from "reactstrap";
// import { useSelector } from "react-redux"

export default (props) => {
    // const { order } = useSelector(state => state.orders);

    // const getDeliveryDate = (order) => {
    //     let deliveryDate;
    //     order.meta_data.forEach(item => {
    //         if (item.key === '_order_delivery') {
    //             deliveryDate = new Date(Number(item.value) * 1000);
    //         }
    //     });

    //     return deliveryDate;
    // }

    // const getHoursLeft = (order) => {    
    //     const deliveryDate = getDeliveryDate(order);

    //     if (deliveryDate)
    //         return ((deliveryDate.getTime() - (new Date()).getTime()) / (60 * 60 * 1000)).toFixed(2);

    //     return false;
    // }

    // const formatLeadingZero = (number) => {
    //     if (number.toString().length >= 2) {
    //         return number;
    //     }

    //     return '0'+ number.toString();
    // }
    
    // const formatDeliveryDate = (date) => {
    //     if (date)
    //         return formatLeadingZero(date.getDate()) +'-'+ formatLeadingZero(date.getMonth() + 1) +'-'+ date.getFullYear() +' '+ formatLeadingZero(date.getHours()) +':'+ formatLeadingZero(date.getMinutes());
        
    //     return "Ingen leveringsdato";
    // }

    const couponStyle = (coupon, style) => {
    //     let hours_left = getHoursLeft(order);

    //     if (hours_left === false) {
    //         return { ...style,
    //             backgroundColor: '#cce5ff',
    //             color: 'black',
    //         };
    //     }

    //     switch (order.status) {
    //         case 'on-hold':
    //             return { ...style,
    //                 backgroundColor: '#e2e3e5',
    //                 color: 'black',
    //             };
    //         case 'processing':
    //             if (hours_left <= 1) {
    //                 return { ...style,
    //                     backgroundColor: '#f8d7da',
    //                     color: 'black',
    //                 };
    //             } else if (hours_left <= 3) {
    //                 return { ...style,
    //                     backgroundColor: '#fff3cd',
    //                     color: 'black',
    //                 };
    //             }
                
    //             return { ...style,
    //                 backgroundColor: '#d4edda',
    //                 color: 'black',
    //             };
    //         default:
    //             return { ...style,
    //                 backgroundColor: '#f8f9fa',
    //                 color: 'black',
    //             };
    //     }
        return style;
    }

    const renderDataList = () => {
        return <ListGroup flush>
            <ListGroupItem><b>Saldo</b><br />kr. {props.coupon.amount}</ListGroupItem>
            {/* <ListGroupItem><b>Anvendt</b><br />{coupon.usage_count} {coupon.usage_count === 1 ? 'gang' : 'gange'}</ListGroupItem> */}
        </ListGroup>
    }

    return (
        <Card style={couponStyle(props.coupon, { margin: '0px 0px 30px 0px', minHeight: '145px', cursor: 'pointer' })}>
            <CardBody onMouseDown={() => { props.onMouseDown(props.coupon) }}>
                <CardTitle><i className="fa fa-gift" style={{marginRight: '8px', marginBottom: '1px'}} /><b>{props.coupon.code}</b></CardTitle>
                { renderDataList() }
            </CardBody>
        </Card>
    )
}
