const initialState = {
    name: '',
    channel: '',
    auth: {
        username: '',
        password: ''
    },
    receipt: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_INFO':
            return { ...state, name: action.data.name ? action.data.name : '' };
        case 'SET_RECEIPT_DATA':
            return { ...state, receipt: action.data };
        case 'SET_CHANNEL':
            return { ...state, channel: action.data };
        case 'SET_AUTH':
            return { ...state, auth: {
                username: action.data.username ? action.data.username : '',
                password: action.data.password ? action.data.password : ''
            }};
        default:
            return state;
    }
}
