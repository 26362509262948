import React, { useEffect, useState, useRef } from "react";
import { getOrder, searchOrders, setSelectedOrder } from '../../context/actions/orders';
import Layout from '../../layouts/Main'
import { Form, FormGroup, Label, Container, InputGroupAddon, Button, InputGroup, Nav, NavItem, NavLink, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import OrderCardDeck from '../../components/OrderCardDeck';
import OrderModal from '../../components/OrderModal';

/**
 * /orders/search
 */
export default () => {
    const { orders } = useSelector(state => state.orders);
    const [searchQuery, setSearchQuery] = useState('');
    // const [ searchResults, setSearchResults ] = useState([]);
    const [searchTimeout, setSearchTimeout] = useState('');
    const [inputType, setInputType] = useState('number');
    const dispatch = useDispatch();
    // const [ showOrder, setShowOrder ] = useState(false);

    const orderFilter = JSON.parse(window.sessionStorage.getItem('order-search-filter'));

    const _defaultShowCancelled = orderFilter ? (orderFilter['showCancelled'] ?? true) : true;
    const _defaultShowProcessing = orderFilter ? (orderFilter['showProcessing'] ?? true) : true;
    const _defaultShowCompleted = orderFilter ? (orderFilter['showCompleted'] ?? true) : true;
    const _defaultShowOnHold = orderFilter ? (orderFilter['showOnHold'] ?? true) : true;

    const [showCancelled, setShowCancelled] = useState(_defaultShowCancelled);
    const [showProcessing, setShowProcessing] = useState(_defaultShowProcessing);
    const [showCompleted, setShowCompleted] = useState(_defaultShowCompleted);
    const [showOnHold, setShowOnHold] = useState(_defaultShowOnHold);
    // const [showOther, setShowOther] = useState(true);

    const getShowStatus = () => {
        let status = [];

        if (showCancelled) {
            status.push('wc-cancelled');
        }

        if (showProcessing) {
            status.push('wc-processing');
        }

        if (showCompleted) {
            status.push('wc-completed');
        }

        if (showOnHold) {
            status.push('wc-on-hold');
        }

        if (status.length === 0) {
            return ['none'];
        }

        return status.join(',')
    }

    useEffect(() => {
        dispatch(searchOrders({
            q: searchQuery,
            status: getShowStatus(),
            limit: 90,
        }));

        window.sessionStorage.setItem('order-search-filter', JSON.stringify({
            showCancelled,
            showProcessing,
            showCompleted,
            showOnHold
        }))

        if (searchInput.current) {
            searchInput.current.focus();
        }

        searchInput.current.focus();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ searchQuery, showCancelled, showProcessing, showCompleted, showOnHold ]);

    const searchInput = useRef(null);

    const handleSearchOrders = query => {
        dispatch(searchOrders({
            q: query,
            status: getShowStatus(),
            limit: 90,
        })).then(res => {
            // if (res['data'] !== undefined) {
            //     setSearchResults(res.data);
            // } else {
            //     setSearchResults([]);
            // }
        }).finally(() => {

        });
    };

    const updateOrderList = () => {
        handleSearchOrders(searchQuery);
    }

    const handleInputType = e => {
        e.preventDefault()

        setInputType(inputType === 'number' ? 'text' : 'number');
        searchInput.current.focus();
    }


    const handleSearchSubmit = e => {
        e.preventDefault();
        searchInput.current.blur();
        updateOrderList();
    };

    const handleChangeQuery = e => {
        if (searchTimeout) clearTimeout(searchTimeout);

        const query = e.target.value;
        setSearchQuery(query);

        if (query.length >= 3) {
            setSearchTimeout(setTimeout(() => {
                handleSearchOrders(query);
            }, 500));
        }
    }

    return (
        <Layout>
            <Container>
                <Nav fill style={{ marginBottom: 20 }}>
                    <NavItem style={{ width: '25%' }}>
                        <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-danger"><Input type="checkbox" onChange={e => { setShowCancelled(!showCancelled) }} checked={showCancelled} /><span>Afvist</span></label></NavLink>
                    </NavItem>
                    <NavItem style={{ width: '25%' }}>
                        <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-warning"><Input type="checkbox" onChange={e => { setShowProcessing(!showProcessing) }} checked={showProcessing} /><span>Behandler</span></label></NavLink>
                    </NavItem>
                    <NavItem style={{ width: '25%' }}>
                        <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-success"><Input type="checkbox" onChange={e => { setShowCompleted(!showCompleted) }} checked={showCompleted} /><span>Gennemført</span></label></NavLink>
                    </NavItem>
                    <NavItem style={{ width: '25%' }}>
                        <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-light"><Input type="checkbox" onChange={e => { setShowOnHold(!showOnHold) }} checked={showOnHold} /><span>Afventer</span></label></NavLink>
                    </NavItem>
                    {/* <NavItem style={{ width: '20%' }}>
                        <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox"><Input type="checkbox" onChange={e => { setShowOther(!showOther) }} checked={showOther} /><span>Andet</span></label></NavLink>
                    </NavItem> */}
                </Nav>
                <Form onSubmit={handleSearchSubmit}>
                    <FormGroup style={{ marginBottom: '30px' }}>
                        <Label for="order_id">Ordre søgning:</Label>
                        <InputGroup>
                            <input type={inputType} name="order_id" className="form-control-lg form-control" onChange={handleChangeQuery} value={searchQuery} ref={searchInput} />
                            <InputGroupAddon addonType="append">
                                <Button style={{ width: '75px' }} color="secondary" onMouseDown={handleInputType}><i className={inputType === 'number' ? 'fa fa-keyboard' : 'fa fa-calculator'} /></Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Form>
                {/* <OrderCardDeck view="search" orders={searchResults} onMouseDown={order => { dispatch(setSelectedOrder(order)) }} /> */}
                <OrderCardDeck view="search" orders={orders} onMouseDown={order => { dispatch(getOrder(order.id)) }} />
                <OrderModal toggle={() => { dispatch(setSelectedOrder(false)) }} />
            </Container>
        </Layout>
    )
}
