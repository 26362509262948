import React from "react";
import { useSelector } from "react-redux"

export default () => {
    const { apiOnline, printerOnline } = useSelector(state => state.services);
    const { version, update } = useSelector(state => state.app);

    return (
        <div className="statusbar">
            <ul className="statusbar-left">
                <li><i className="fa fa-server" style={{ color: (apiOnline ? 'green' : 'red') }} /></li>
                <li><i className="fa fa-print" style={{ color: (printerOnline ? 'green' : 'red') }} /></li>
            </ul>
            <ul className="statusbar-right">
                <li className="version">{update ? version +' => '+ update : version}</li>
            </ul>
        </div>
    )
}
