const initialState = {
    loadingOrders: true,
    loadingOrder: true,
    orders: [],
    searchResults: [],
    order: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ORDERS':
            return { ...state, orders: action.data };
        case 'SET_ORDERS_LOADING':
            return { ...state, loadingOrders: action.data };
        case 'SET_ORDER':
            return { ...state, order: action.data };
        case 'SET_ORDER2':
            if (!action.data) {
                return { ...state, order: null };
            }
            
            for (let index in state.orders) {
                if (state.orders[index].id === action.data.id) {
                    return { ...state, order: state.orders[index] };
                }
            }
            return state;
        case 'SET_SEARCH_RESULT_ORDER':
            if (!action.data) {
                return { ...state, order: null };
            }
            
            for (let index in state.searchResults) {
                if (state.searchResults[index].id === action.data.id) {
                    return { ...state, order: state.searchResults[index] };
                }
            }
            return state;
        case 'SET_ORDER_IN_LISTS':

            const orders = [ ...state.orders ];
            // ...
            for (let index in orders) {
                if (orders[index].id === action.data.id) {
                    orders[index] = action.data;
                }
            }

            const searchResults = [ ...state.searchResults ];

            // ...
            for (let index in searchResults) {
                if (searchResults[index].id === action.data.id) {
                    searchResults[index] = action.data;
                }
            }

            return { ...state, orders, searchResults };
        case 'SET_ORDER_LOADING':
            return { ...state, loadingOrder: action.data };
        case 'SET_SEARCH_RESULTS':
            return { ...state, searchResults: action.data };
        case 'SET_SELECTED_ORDER':
            return { ...state, order: action.data };
        default:
            return state;
    }
}
