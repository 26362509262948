import Axios from "axios"
import settings from '../../settings';
import StarWebPrint from '../../services/print';

export const setupData = (host, data) => {
    return async (dispatch) => {
        dispatch(setAuth(host, data.token))
        dispatch(setName(data.name))
        // dispatch(setChannel(data.channel))
        dispatch(setReceiptData(data.receipt))

        StarWebPrint.setup(data.receipt);
    }
}

/**
 *
 * @param {*} shop
 * @param {*} username
 * @param {*} password
 */
export const getToken = (host, username, password) => {
    return async (dispatch) => {
        return await Axios.post(`${settings.API_HOST}/user/login`, {
            host,
            username,
            password
        }).then(res => {

            dispatch(setupData(host, res.data.data));

            window.sessionStorage.setItem('auth', JSON.stringify({ 
                username: host, 
                password: res.data.data.token
            }));

            window.sessionStorage.setItem('data', JSON.stringify(res.data.data));

            return res.data;
        }).catch(error => {
            if (typeof error.response !== 'undefined') {
                if (typeof error.response.data == "object" && error.response.data.hasOwnProperty('data') && typeof error.response.data.data == "string" && error.response.data.data !== "") {
                    return {
                        success: false,
                        data: error.response.data.data
                    };
                }

                return {
                    success: false,
                    data: "Tjek venlist at du har indtastet butikkens domæne korrekt."
                }
            } else {
                return {
                    success: false,
                    data: "Der opstod en fejl, prøv venligst igen senere."
                }
            }
        })
    }
}

export const doLogout = () => {
    window.sessionStorage.removeItem('auth');
    window.sessionStorage.removeItem('data');
    return async (dispatch) => {
        return await dispatch(setAuth('', ''))
    }
}

export const getAuth = () => {
    return () => {
        return new Promise(resolve => {
            const auth = JSON.parse(window.sessionStorage.getItem('auth')) || { username: '', password: '' } ;
            resolve(auth)
        })
    }
}

/**
 *
 * @param {*} username
 * @param {*} password
 */
export const setAuth = (username, password) => {
    return { type: 'SET_AUTH', data: {
        username, password
    }}
}

/**
 *
 * @param {*} name
 */
const setName = name => {
    return { type: 'SET_INFO', data: {
        name: name
    }}
}

/**
 *
 * @param {*} channel
 */
// const setChannel = data => {
//     return { type: 'SET_CHANNEL', data }
// }

/**
 *
 * @param {*} channel
 */
const setReceiptData = data => {
    return { type: 'SET_RECEIPT_DATA', data }
}
