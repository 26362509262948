import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../../layouts/Main'

import { getOrder, searchOrders, setSelectedOrder } from '../../context/actions/orders';
import { Nav, NavItem, NavLink, Input } from "reactstrap";
import { isRead } from '../../helpers/order';

import OrderModal from '../../components/OrderModal';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/da';


import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Container } from 'reactstrap';

moment.locale('da');
const localizer = momentLocalizer(moment)

function Cal() {
    const dispatch = useDispatch();

    const [orderItems, setOrderItems] = useState([]);

    const orderFilter = JSON.parse(window.sessionStorage.getItem('order-calendar-filter'));

    const _defaultShowProcessing = orderFilter ? (orderFilter['showProcessing'] ?? true) : true;
    const _defaultShowCompleted = orderFilter ? (orderFilter['showCompleted'] ?? true) : true;
    const _defaultShowOnHold = orderFilter ? (orderFilter['showOnHold'] ?? true) : true;

    const [ showProcessing, setShowProcessing ] = useState(_defaultShowProcessing);
    const [ showCompleted, setShowCompleted ] = useState(_defaultShowCompleted);
    const [ showOnHold, setShowOnHold ] = useState(_defaultShowOnHold);

    const [ dateFirst, setDateFirst ] = useState(moment().startOf('month').toDate());
    const [ dateLast, setDateLast ] = useState(moment().endOf('month').toDate());

    const handleEventClick = (event) => {
        dispatch(getOrder(event.resource.id))
    }

    const getShowStatus = () => {
        let status = [];

        if (showOnHold) {
            status.push('wc-on-hold');
        }

        if (showCompleted) {
            status.push('wc-completed');
        }

        if (showProcessing) {
            status.push('wc-processing');
        }

        if (status.length === 0) {
            return [ 'none' ];
        }

        return status.join(',')
    }

    /**
     *
     * @param {*} order
     */
    const getDeliveryDate = (order) => {
        const entries = Object.entries(order.meta_data);
        for (let index = 0; index < entries.length; index++) {
            const entry = entries[index];
            if (entry[1].key === '_order_delivery') {
                return Number(entry[1].value);
            }
        }

        return 0;
    }

    const fixOrders = (orders) => {
        const _orderItems = [];
        for (let index = 0; index < orders.length; index++) {
            const order    = orders[index];
            const delivery = getDeliveryDate(order);

            _orderItems.push({
                title:    order.id,
                allDay:   delivery === 0,
                resource: order,
                start:    delivery > 0 ? moment.unix(delivery).toDate() : new Date(),
                end:      delivery > 0 ? moment.unix(delivery).toDate() : new Date(),
            })
        }

        setOrderItems(_orderItems);
    }

    const eventProps = (event) => {
        return {
            style: orderStyle2(event.resource, orderStyle(event.resource, {}))
        };
    }
    
    const orderStyle2 = (order, style) => {

        // ...
        if (isRead(order))
            return style;

        // ...
        return { ...style,
            border: "2px solid black"
        };
    }

    /**
     *
     * @param {*} order
     * @param {*} style
     */
    const orderStyle = (order, style) => {
        switch (order.status) {
            case 'on-hold':
                return {
                    ...style,
                    backgroundColor: '#f8f9fa', // grey
                    color: 'black',
                };
            case 'cancelled':
                return {
                    ...style,
                    backgroundColor: '#f8d7da', // red
                    color: 'black',
                };
            case 'processing':
                return {
                    ...style,
                    backgroundColor: '#fff3cd', // yellow
                    color: 'black',
                };
            case 'completed':
                return {
                    ...style,
                    backgroundColor: '#d4edda', // green
                    color: 'black',
                };
            default:
                return {
                    ...style,
                    backgroundColor: '#ffffff', // white
                    color: 'black',
                };
        }
    }

    const handleReload = () => {
        setDateFirst(moment().startOf('month').toDate());
        setDateLast(moment().endOf('month').toDate());
    }

    useEffect(() => {
        dispatch(searchOrders({
            status:  getShowStatus(),
            between: `${Math.floor(dateFirst.getTime() / 1000)}:${Math.floor(dateLast.getTime() / 1000)}`
        })).then(res => {
            fixOrders(res.data)
        })

        window.sessionStorage.setItem('order-calendar-filter', JSON.stringify({
            showProcessing,
            showCompleted,
            showOnHold
        }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dateFirst, dateLast, showCompleted, showOnHold, showProcessing ])

    return <Layout onReload={() => handleReload()}>
        <Container>
            <Calendar
                localizer={localizer}
                events={orderItems}
                step={15}
                timeslots={4}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '80vh' }}
                views={['month', 'week', 'day']}
                onSelectEvent={handleEventClick}
                onRangeChange={range => {
                    setDateFirst(range.start);
                    setDateLast(range.end);
                }}
                scrollToTime={moment().subtract(1, 'hour').toDate()}
                defaultView={Views.MONTH}
                // length={7}
                popup={true}
                eventPropGetter={eventProps}
                messages={{
                    today: 'I dag',
                    previous: 'Tidligere',
                    next: 'Senere',
                    month: 'Måned',
                    week: 'Uge',
                    day: 'Dag',
                    agenda: 'Oversigt',
                    showMore: function (e) {
                        return '+' + e + ' mere'
                    }
                }}
            // toolbar={false}
            />
            <Nav fill style={{ marginTop: 15 }}>
                <NavItem style={{ width: '33%' }}>
                    <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-warning"><Input type="checkbox" onChange={e => { setShowProcessing(!showProcessing) }} checked={showProcessing} /><span>Behandler</span></label></NavLink>
                </NavItem>
                <NavItem style={{ width: '33%' }}>
                    <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-success"><Input type="checkbox" onChange={e => { setShowCompleted(!showCompleted) }} checked={showCompleted} /><span>Gennemført</span></label></NavLink>
                </NavItem>
                <NavItem style={{ width: '33%' }}>
                    <NavLink tag="div" style={{ fontSize: '11px' }}><label className="checkbox checkbox-light"><Input type="checkbox" onChange={e => { setShowOnHold(!showOnHold) }} checked={showOnHold} /><span>Afventer</span></label></NavLink>
                </NavItem>
            </Nav>
        </Container>
        <OrderModal toggle={() => { dispatch(setSelectedOrder(false)) }} />
    </Layout>
}

export default Cal;
