import Axios from "axios"
import { formatLeadingZero } from '../../helpers/number';
import { updateLoading, setNewOrders } from './app';
import settings from '../../settings';

/**
 *
 */
 export const getOrders = (params) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));

        return Axios.get(`${settings.API_HOST}/fd-orders`, {
            auth: getState().user.auth,
            params
        }).then(res => {
            dispatch(updateLoading(false));
            dispatch(setOrders(res.data.data));
            dispatch(setNewOrders(false));
        }).catch(res => {
            dispatch(updateLoading(false));
        })
    }
}

/**
 *
 */
export const searchOrders = (params) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));

        return Axios.get(`${settings.API_HOST}/fd-orders`, {
            auth: getState().user.auth,
            params
        }).then(res => {
            if (res.data['data'] !== undefined) {
                dispatch(setOrders(res.data.data));
            } else {
                dispatch(setOrders([]));
            }
            dispatch(updateLoading(false));
            dispatch(setNewOrders(false));
            return res.data;
        }).catch(res => {
            dispatch(updateLoading(false));
        })
    }
}

/**
 *
 */
 export const fetchOrder = (id) => {
    return (dispatch, getState) => {
        return Axios.get(`${settings.API_HOST}/orders/${id}`, {
            auth: getState().user.auth
        }).then(res => {
            return res.data.data
        })
    }
}

/**
 *
 */
 export const getOrder = (id) => {
    return (dispatch, getState) => {
        return Axios.get(`${settings.API_HOST}/orders/${id}`, {
            auth: getState().user.auth
        }).then(res => {
            dispatch(setSelectedOrder(res.data.data))
            return res.data
        })
    }
}

/**
 *
 */
export const setSelectedOrder = order => {
    return dispatch => {
        dispatch(setSelectedOrderAction(order));
    }
}

/**
 *
 */
export const oldSearchOrders = q => {
    return (dispatch, getState) => {
        var oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        const after = oneYearAgo.getFullYear() +'-'+ formatLeadingZero(oneYearAgo.getMonth()) +'-'+ formatLeadingZero(oneYearAgo.getDate());

        return Axios.get(`${settings.API_HOST}/orders?per_page=90&search=${q}&after=${after}T00:00:00&status=on-hold,processing,completed,cancelled`, {
            auth: getState().user.auth
        }).then(res => {
            dispatch(setSearchResults(res.data.data));
            dispatch(updateLoading(false));
        }).catch(res => {
            dispatch(updateLoading(false));
        })
    }
}

/**
 *
 * @param {*} id
 * @param {*} data
 */
export const updateOrder = (id, data) => {
    return async (dispatch, getState) => {
        dispatch(updateLoading(true));
        return await Axios.put(`${settings.API_HOST}/orders/${id}`, data, {
            auth: getState().user.auth
        }).then(res => {
            dispatch(setSelectedOrderAction(res.data.data));
            dispatch(setOrderInLists(res.data.data));
            // dispatch(setOrder(res.data.data));
            dispatch(updateLoading(false));
            return res.data.data;
        }).catch(res => {
            dispatch(updateLoading(false));
        })
    }
}

const setSelectedOrderAction = data => {
    return { type: 'SET_SELECTED_ORDER', data }
}

const setOrderInLists = data => {
    return { type: 'SET_ORDER_IN_LISTS', data }
}

const setSearchResults = data => {
    return { type: 'SET_SEARCH_RESULTS', data }
}

const setOrders = data => {
    return { type: 'SET_ORDERS', data }
}
